import {  createActions } from 'redux-actions';

const actions = createActions({
    SET_LOCATION_LIST: (list) => list,
    PUSH_LOCATION: (location) => location,
    UPDATE_LOCATION: (location) => location,
    SET_SELECTED_LOCATION_LIST: (list) => list,
    PUSH_SELECTED_LOCATION: (location) => location,
    REMOVE_SELECTED_LOCATION: (location) => location,
});

const { setLocationList, pushLocation, pushSelectedLocation, setSelectedLocationList, updateLocation, removeSelectedLocation } = actions;

export default {
    setLocationList,
    setSelectedLocationList,
    pushLocation,
    pushSelectedLocation,
    updateLocation,
    removeSelectedLocation
};
