import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {useFormContext, Controller} from "react-hook-form";
import {useGeography} from "../hooks";
import {Col, FormGroup, Label, ModalBody, Row, Button} from "reactstrap";
import Select from "react-select";
import defaultImage from "../assets/img/default-avatar.png";

function PersonForm({ fieldName, index, defaultValue, blurDocument, inputPhoto, inputWorked }) {
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const { getStateCities, stateList, cityList } = useGeography();
    const { formState, register, errors, control, setValue, getValues, watch } = useFormContext();
    const fieldNameId = `${fieldName}[${index}]`;
    // const isWorked = watch(`${fieldNameId}.isWorked`, inputWorked);
    // const isPhoto = watch(`${fieldNameId}.file`, inputPhoto);

    const getControlState = (name) => (
        `has-label ${
            !!formState.touched[fieldName]
                ? !!formState.touched[fieldName][index]
                    ? !!formState.touched[fieldName][index][name]
                        ? !!errors[fieldName]
                            ? !!errors[fieldName][index]
                                ? !!errors[fieldName][index][name] ? 'has-danger' : 'has-success'
                                : 'has-success'
                            : ''
                        : ''
                    : ''
                : ''
        }`
    );

    const handleStateSelect = ([selected]) => {
        const city =  getValues()[`${fieldNameId}.city`];
        const state =  getValues()[`${fieldNameId}.state`];
        if(!!city && state !== selected) {
            const objectChange = {};
            objectChange[`${fieldNameId}.city`] = null;
            setValue([objectChange]);
        }
        // setStateSelected(state)
        return selected;
    };

    const handleImageChange = (event) => {
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        reader.onloadend = () => {
            setValue(`${fieldNameId}.file`, file); // you get all the files object here
            setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const mapCities = (values) => getStateCities(values[`${fieldNameId}.state`]?.id);

    const getPic = (values) => !!defaultValue?.url && !values[`${fieldNameId}.file`] ? defaultValue.url : imagePreviewUrl;

    useEffect(() => {
        if (defaultValue) {
            const valueCity = cityList.find(item => item.id === defaultValue.cityId);
            const fieldNameValues = {};
            fieldNameValues[`${fieldNameId}`] = {
                firstName: defaultValue?.firstName || null,
                lastName: defaultValue?.lastName || null,
                dateOfBirth: defaultValue.dateOfBirth ? defaultValue?.dateOfBirth.split(' ')[0] : null,
                height: defaultValue?.height || null,
                phone: defaultValue?.phone || null,
                email: defaultValue?.email || null,
                address: defaultValue?.address || null,
                documentType: documentTypeList.find(item => item.value === defaultValue.documentType),
                gender: genderList.find(item => item.value === defaultValue.gender),
                bloodType: bloodList.find(item => item.value === defaultValue.bloodType),
                academicTraining: academicList.find(item => item.value === defaultValue.academicTraining),
                state: stateList.find(item => item.id === valueCity?.stateId),
                city: valueCity
            };
            setValue([fieldNameValues]);
        }
    }, [defaultValue]);

    useEffect(() => {
        register({ name: `${fieldNameId}.file` })
        // register({ name: 'file' }, { required: true }) // still have validation for required
    }, [register]);

    return(
        <fieldset name={fieldNameId} key={fieldNameId}>
            <Row>
                <Col xs={12} md={6}>
                    <FormGroup className={getControlState('documentType')}>
                        <Label>Tipo de Documento*</Label>
                        <Controller
                            as={<Select
                                options={documentTypeList}
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder="Seleccione tipo de documento de la persona"
                            />}
                            name={`${fieldNameId}.documentType`}
                            control={control}
                            onChange={([selected]) => selected}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup className={getControlState('documentNumber')}>
                        <Label>Identificación*</Label>
                        <input
                            type="text"
                            name={`${fieldNameId}.documentNumber`}
                            className="form-control"
                            onBlur={(e => blurDocument(e, index))}
                            ref={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <FormGroup className={getControlState('firstName')}>
                        <Label>Nombres*</Label>
                        <input
                            type="text"
                            name={`${fieldNameId}.firstName`}
                            className="form-control"
                            ref={register}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup className={getControlState('lastName')}>
                        <Label>Apellidos*</Label>
                        <input
                            type="text"
                            name={`${fieldNameId}.lastName`}
                            className="form-control"
                            ref={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <FormGroup className={getControlState('dateOfBirth')}>
                        <label>Fecha de nacimiento</label>
                        <input
                            type="date"
                            name={`${fieldNameId}.dateOfBirth`}
                            className="form-control"
                            ref={register}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup className={getControlState('gender')}>
                        <Label>Género*</Label>
                        <Controller
                            as={<Select
                                options={genderList}
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder="Seleccione género de la persona"
                            />}
                            name={`${fieldNameId}.gender`}
                            control={control}
                            onChange={([selected]) => selected}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <FormGroup className={getControlState('height')}>
                        <Label>Estatura (centimetros)*</Label>
                        <input
                            type="number"
                            name={`${fieldNameId}.height`}
                            min={0}
                            className="form-control"
                            ref={register}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup className={getControlState('bloodType')}>
                        <Label>Tipo de sangre*</Label>
                        <Controller
                            as={<Select
                                options={bloodList}
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder="Seleccione su tipo de sangre"
                            />}
                            name={`${fieldNameId}.bloodType`}
                            control={control}
                            onChange={([selected]) => selected}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <FormGroup className={getControlState('phone')}>
                        <Label>Telefono*</Label>
                        <input
                            type="text"
                            name={`${fieldNameId}.phone`}
                            className="form-control"
                            ref={register}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup className={getControlState('academicTraining')}>
                        <Label>Nivel academico*</Label>
                        <Controller
                            as={<Select
                                options={academicList}
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder="Seleccione su nivel academico"
                            />}
                            name={`${fieldNameId}.academicTraining`}
                            control={control}
                            onChange={([selected]) => selected}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <FormGroup className={getControlState('state')}>
                        <Label>Departamento*</Label>
                        <Controller
                            as={<Select
                                options={stateList}
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder="Seleccione un departamento"
                                getOptionLabel={option => `${option.name}`}
                                getOptionValue={option => `${option.id}`}
                            />}
                            name={`${fieldNameId}.state`}
                            control={control}
                            onChange={handleStateSelect}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup className={getControlState('city')}>
                        <Label>Ciudad*</Label>
                        <Controller
                            as={<Select
                                options={mapCities(getValues())}
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder="Seleccione un municipio"
                                getOptionLabel={option => `${option.name}`}
                                getOptionValue={option => `${option.id}`}
                                noOptionsMessage={() => null}
                            />}
                            name={`${fieldNameId}.city`}
                            control={control}
                            onChange={([selected]) => selected}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <FormGroup className={getControlState('address')}>
                        <Label>Dirección*</Label>
                        <input
                            type="text"
                            name={`${fieldNameId}.address`}
                            className="form-control"
                            ref={register}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup className={getControlState('email')}>
                        <Label>Email*</Label>
                        <input
                            type="text"
                            name={`${fieldNameId}.email`}
                            className="form-control"
                            ref={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                {inputPhoto &&
                    <Col xs={12} md={6}>
                    <div className="picture-container">
                        <div className="picture">
                            <img
                                src={getPic(getValues())}
                                className="picture-src"
                                alt="..."
                            />
                            <input type="file" onChange={handleImageChange} accept="image/*"/>
                        </div>
                        <h6 className="description">Selecciona una Foto</h6>
                    </div>
                </Col>}
                <Col xs={6} md={6}>
                    <FormGroup check>
                        {
                            inputWorked &&
                            <Label check>
                                <input
                                    type="checkbox"
                                    name={`${fieldNameId}.isWorked`}
                                    ref={register}
                                />
                                <span className="form-check-sign" />
                                ¿Has trabajado con nosotros?
                            </Label>
                        }
                    </FormGroup>
                </Col>
            </Row>
        </fieldset>
    );
}

PersonForm.propTypes = {
    defaultValue: PropTypes.object,
    blurDocument: PropTypes.func
};

PersonForm.defaultProps = {
    defaultValue: null,
    blurDocument: null
};

export default PersonForm;

const documentTypeList = [
    {
        value: 'CC',
        label: 'Cédula de ciudadanía'
    },
    {
        value: 'TI',
        label: 'Tarjeta de identidad'
    },
    {
        value: 'RC',
        label: 'Registro Civil'
    },
    {
        value: 'CE',
        label: 'Cédula de extranjería'
    },
    {
        value: 'PP',
        label: 'Pasaporte'
    },
];

const genderList = [
    {
        value: 'M',
        label: 'Masculino'
    },
    {
        value: 'F',
        label: 'Femenino'
    },
    {
        value: 'O',
        label: 'Otro'
    }
];

const academicList = [
    {
        value: 'BACHILLER',
        label: 'Bachiller'
    },
    {
        value: 'TECNICO',
        label: 'Técnico'
    },
    {
        value: 'PROFESIONAL',
        label: 'Profesional'
    },
    {
        value: 'POSGRADO',
        label: 'Posgrado'
    }
];

const bloodList = [
    {
        value: 'A+',
        label: 'A+ (Positivo)'
    },
    {
        value: 'A-',
        label: 'A- (Negativo)'
    },
    {
        value: 'AB+',
        label: 'AB+ (Positivo)'
    },
    {
        value: 'AB-',
        label: 'AB- (Negativo)'
    },
    {
        value: 'O+',
        label: 'O+ (Positivo)'
    },
    {
        value: 'O-',
        label: 'O- (Negativo)'
    },
];
