import React from 'react';
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";

function ModalSelectEvent ({ isOpen, toggle, eventList, toggleSelected }) {

    const selectOption = (id) => {
        toggleSelected(id);
        toggle()
    };

    return(
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}><strong>Elegir Evento</strong></ModalHeader>
            <ModalBody>
                {eventList.length <= 0 ?
                    'No hay eventos disponibles, por favor cree uno' :
                    eventList.map((item, key) => (
                        <Row key={key}>
                            <Col md={4} xs={1} />
                            <Col md={6} xs={10}>
                                <Button
                                    color="info" className="btn-round float-none"
                                    onClick={() => selectOption(item.id)}
                                >{item.name}</Button>
                            </Col>
                            <Col md={2} xs={2} />
                        </Row>
                    ))
                }
            </ModalBody>
        </Modal>
    );
}

export default ModalSelectEvent;