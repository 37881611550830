import { handleActions } from 'redux-actions';
import { locationKeys } from '../keys';

const {
    SET_LOCATION_LIST,
    PUSH_LOCATION,
    UPDATE_LOCATION,
    SET_SELECTED_LOCATION_LIST,
    PUSH_SELECTED_LOCATION,
    REMOVE_SELECTED_LOCATION
} = locationKeys;

const locationsReducer = handleActions({
    [SET_LOCATION_LIST]: (state, action) => ({
        ...state,
        list: action.payload
    }),
    [PUSH_LOCATION]: (state, action) => ({
        ...state,
        ...state.list.push(action.payload)
    }),
    [UPDATE_LOCATION]: (state, action) => {
        const index = state.list.findIndex((location) => location.id === action.payload.id);
        const list = state.list;
        list[index] = action.payload;
        return ({
            ...state,
            list
        })
    },
    [SET_SELECTED_LOCATION_LIST]: (state, action) => ({
        ...state,
        selectedList: action.payload
    }),
    [PUSH_SELECTED_LOCATION]: (state, action) => ({
        ...state,
        ...state.selectedList.push({
            id: action.payload.id,
            locationId: action.payload.placeId
        })
    }),
    [REMOVE_SELECTED_LOCATION]: (state, action) => {
        const index = state.selectedList.findIndex((location) => location.id === action.payload);
        return ({
            ...state,
            ...state.selectedList.splice(index, 1)
        })
    },
}, {
    list: [],
    selectedList: []
});

export default locationsReducer;
