import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {ModalPerson, PanelHeader} from "../../components";
import { useApi, useLocations, useEvents } from "../../hooks";
import { useForm } from "react-hook-form";
import ReactTable from 'react-table-v6';
import Select from "react-select";
import * as yup from 'yup';
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    FormGroup,
    Form,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Container
} from "reactstrap";

const schema = yup.object().shape({
    document: yup.number().required(),
});

const userImage = '../../assets/img/user.jpg';

function Accreditation () {

    const { fetcher, getPersonDocumentNumber } = useApi();
    const { formState, handleSubmit, register, errors } = useForm({ mode: 'onBlur', validationSchema: schema });
    const { selectedEvent, locationList, locationsEvent } = useLocations();
    const { eventList } = useEvents();
    const history = useHistory();

    const getControlState = (name) => `has-label ${!formState.touched[name] ? '' : errors[name] ? 'has-danger' : 'has-success'}`

    const [ person, setPerson ] = useState(null);
    const [ places, setPlaces ] = useState([]);
    const [ zones, setZones ] = useState([]);
    const [ place, setPlace ] = useState(null);
    const [ daysEvent, setDaysEvent ] = useState([]);
    const [ isOpenModalPerson, setIsOpenModalPerson ] = useState(false);

    const eventInfo = eventList.find(item => item.id === selectedEvent);

    const verifyDocumentNumber = async (event) => {
        const value = event.target.value;
        const {response, error} = await getPersonDocumentNumber(value);
        if (response && response.statusText === 'OK') {
            const { data } = response;
            setPerson(data.data);
        } else if (error) {
            if (error.message.search('401') > 0) history.push('/auth/login');
            if (error.message.search('404') > 0) {
                setIsOpenModalPerson(true);
                setPerson({documentNumber: value})
            }
        }
    };

    useEffect(() => {
        if(eventInfo){
            const allDates = getDates(new Date(eventInfo.dateStart), new Date(eventInfo.dateEnd));
            setDaysEvent(allDates);
            // console.log(allDates);

            /*let array = [];
            const days = getDays(eventInfo.dateStart, eventInfo.dateEnd);
            let startDate = new Date(eventInfo.dateStart); 
            let date = new Date(eventInfo.dateStart);
            for (let i = 0; i<days; i++){
                const newDate = date.setDate(startDate.getDate() + i);
                date = new Date(newDate);
                array.push(date.toLocaleDateString());
            }
            setDaysEvent(array);*/
            //console.log(date);
        }
    }, [eventInfo]);

    useEffect(() => {
        setPlaces(locationList.filter(location => locationsEvent.find(sel => sel.locationId === location.id)));
    }, [locationsEvent]);

    useEffect(() => {
        if (place)
            getZones();
    }, [place]);

    useEffect(() => {
        setPlace(null);
        setZones([]);
    }, [places]);

    const handleSelectPlace = value =>{
        setPlace(value);
    }

    const getZones = async () => {
        const {response, error} = await fetcher('GET',`event/${selectedEvent}/place/${place.id}/zone`);
        if (response){
            const {data, statusText} = response;
            if (statusText === "OK"){
                setZones(data.data);
            }
        }else if (error)
            console.log(error)
    }

    const onSubmit = (event) => {
        event.preventDefault();
    }

    const getDays = (start, end) => {
        const date1 = new Date(start);
        const date2 = new Date(end);
        const diffTime = Math.abs(date2 - date1);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    };

    const getDates = (startDate, endDate) => {
        const dates = [];
        let currentDate = startDate;
        const addDays = function(days) {
            const date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };

        while (currentDate <= endDate) {
            dates.push(currentDate);
            currentDate = addDays.call(currentDate, 1);
        }
        return dates;
    };
    
    return(
        <>
            <PanelHeader size="sm"/>
            <div className="content">
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xs={12} md={12}>
                                <Row>
                                    <Col xs={12} md={10}>
                                        <CardTitle tag="h4"><strong>Acreditar Personal</strong></CardTitle>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs={6} md={6}>
                                <Form onSubmit={onSubmit}>
                                    <Col xs={12} md={12}>
                                        <FormGroup className={getControlState('document')}>
                                            <label><strong>Buscar por cédula</strong></label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="now-ui-icons users_circle-08" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <input
                                                type="text"
                                                name="document"
                                                className="form-control"
                                                onBlur={verifyDocumentNumber}
                                                ref={register}/>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <Row>
                                            <Col xs={12} md={8}>
                                                <p><strong>Nombre: </strong> Pedro</p>
                                                <p><strong>Empresa : </strong>Fundación festiva vallenato</p>
                                                <p><strong>Categoria: </strong>Concursante</p>
                                                <p><strong>Perfil: </strong>General</p>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <div className="image">
                                                    <img alt="..." src='https://demos.creative-tim.com/material-dashboard-pro-react/static/media/avatar.7a6bc100.jpg' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={12} style={{marginTop: 10}}>
                                        <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        placeholder="Seleccione una localidad"
                                        name="singleSelect"
                                        options={places}
                                        value={place}
                                        getOptionLabel={option => `${option.name}`}
                                        getOptionValue={option => `${option.id}`}
                                        noOptionsMessage={() => null}
                                        onChange={handleSelectPlace}
                                        />
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <Row>
                                            <Col xs={6} md={6}>
                                                <Row>
                                                    {
                                                        zones.map((prop, key) => {
                                                            return(
                                                                <Col xs={6} md={6} key={key}>
                                                                    <FormGroup check>
                                                                        <Label check>
                                                                            <Input 
                                                                            type="checkbox" 
                                                                            value={prop.id}
                                                                            //onChange={onChangePrivileges}
                                                                            //checked={prop.checked}
                                                                            />
                                                                            <span className="form-check-sign" />
                                                                            {prop.name}
                                                                        </Label>
                                                                    </FormGroup>
                                                                </Col>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </Col>
                                            <Col xs={6} md={6}>
                                                <label><strong>Horarios</strong></label>
                                                <Row>
                                                    {
                                                        daysEvent.map( (day, key) => {
                                                            return(
                                                                <Container key={key}>
                                                                    <Row>
                                                                        <Col xs={12} md={12}>
                                                                            <label><strong>{day.toLocaleString()}</strong></label>
                                                                        </Col>                                                    
                                                                        <Col xs={6} md={6}>
                                                                            <label>Entrada</label>
                                                                            <FormGroup>
                                                                                <input
                                                                                type="time"
                                                                                name="start_time"
                                                                                className="form-control"
                                                                                //onChange={ event => setDocument(event.target.value)}
                                                                                //ref={register}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col xs={6} md={6}>
                                                                            <FormGroup>
                                                                                <label>Entrada</label>
                                                                                <input
                                                                                type="time"
                                                                                name="end_time"
                                                                                className="form-control"
                                                                                //onChange={ event => setDocument(event.target.value)}
                                                                                //ref={register}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>   
                                                                    </Row>
                                                                </Container>                                                             
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form>
                            </Col>
                            <Col xs={6} md={6}>
                                <label><strong>Lista de Personal</strong></label>
                                <Row>
                                    <Col xs={4} md={4}>
                                        <label><strong>Buscar por Empresa</strong></label>
                                        <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        placeholder="Empresas"
                                        name="singleSelect"
                                        options={places}
                                        value={place}
                                        getOptionLabel={option => `${option.name}`}
                                        getOptionValue={option => `${option.id}`}
                                        noOptionsMessage={() => null}
                                        onChange={handleSelectPlace}
                                        />
                                    </Col>
                                    <Col xs={4} md={4}>
                                        <label><strong>Buscar por categoria</strong></label>
                                        <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        placeholder="Categorias"
                                        name="singleSelect"
                                        options={places}
                                        value={place}
                                        getOptionLabel={option => `${option.name}`}
                                        getOptionValue={option => `${option.id}`}
                                        noOptionsMessage={() => null}
                                        onChange={handleSelectPlace}
                                        />
                                    </Col>
                                    <Col xs={4} md={4}>
                                        <label><strong>Buscar por perfil</strong></label>
                                        <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        placeholder="Perfiles"
                                        name="singleSelect"
                                        options={places}
                                        value={place}
                                        getOptionLabel={option => `${option.name}`}
                                        getOptionValue={option => `${option.id}`}
                                        noOptionsMessage={() => null}
                                        onChange={handleSelectPlace}
                                        />
                                    </Col>
                                    <Col>
                                        <ReactTable
                                        data={[]}
                                        filterable
                                        columns={[
                                            {
                                                Header: "ID",
                                                accessor: "id"
                                            },
                                            {
                                                Header: "Nombre",
                                                accessor: "name"
                                            },
                                            {
                                                Header: "Empresa",
                                                accessor: "capacity"
                                            },
                                            {
                                                Header: "Perfil",
                                                accessor: "capacity"
                                            },
                                            {
                                                Header: "Estado",
                                                accessor: "capacity"
                                            },
                                        ]}
                                        previousText='Anterior'
                                        nextText='Siguiente'
                                        pageText='Página'
                                        ofText='de'
                                        rowsText='Filas'
                                        showPaginationBottom
                                        minRows={1}
                                        defaultPageSize={18}
                                        pageSizeOptions={[10, 15, 18, 20, 25, 50]}
                                        className="-striped -highlight"/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
            <ModalPerson isOpen={isOpenModalPerson} setOpen={setIsOpenModalPerson} setPerson={setPerson} defaultValue={person} />
        </>
    );
}

export default Accreditation;
