import React, { useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Form,
    Button,
    Col,
    Row,
    FormGroup,
    Label,
    CardBody
} from 'reactstrap';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import Select from "react-select";
import {useApi, useGeography} from "../../hooks";
import {useHistory} from "react-router-dom";

const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    documentNumber: yup.number().required(),
    phone: yup.number().required(),
    address: yup.string().required(),
    email: yup.string().email().required(),
});

const documentTypeList = [
    {
        id: 'CC',
        name: 'Cédula de ciudadanía'
    },
    {
        id: 'TI',
        name: 'Tarjeta de identidad'
    },
    {
        id: 'RC',
        name: 'Registro Civil'
    },
    {
        id: 'CE',
        name: 'Cédula de extranjería'
    },
    {
        id: 'PP',
        name: 'Pasaporte'
    },
];

const genderList = [
    {
        id: 'M',
        name: 'Masculino'
    },
    {
        id: 'F',
        name: 'Femenino'
    }
];

function PersonModal({ isOpen, setOpen, setPerson, defaultValue }) {

    const [ documentTypeSelected, setDocumentTypeSelected ] = useState(null);
    const [ genderSelected, setGenderSelected ] = useState(null);
    const [ stateSelected, setStateSelected ] = useState(null);
    const [ citySelected, setCitySelected ] = useState(null);
    const history = useHistory();

    const { getStateCities, stateList } = useGeography();
    const { insertPerson } = useApi();

    const { formState, handleSubmit, register, errors, reset } = useForm({
        mode: 'onBlur',
        validationSchema: schema
    });

    const getControlState = (name) => `has-label ${!formState.touched[name] ? '' : errors[name] ? 'has-danger' : 'has-success'}`;

    const toggle = () => {
        setOpen(false)
    };

    const handleDocumentTypeSelect = (value) => {
        setDocumentTypeSelected(value)
    };

    const handleGender = (value) => {
        setGenderSelected(value)
    };

    const handleStateSelect = (value) => {
        if(citySelected && stateSelected !== value) setCitySelected(null);
        setStateSelected(value)
    };

    const onSubmit = async (values) => {
        const obj = {
            ...values,
            documentType: documentTypeSelected.id,
            gender: genderSelected.id,
            cityId: citySelected.id
        };
        // { firstName, lastName, documentNumber, phone, address, email }
        const {response, error} = await insertPerson(obj);
        if (response && response.statusText === 'OK') {
            const { data } = response;
            console.log(data.data)
        } else if (error) {
            if (error.message.search('401') > 0) history.push('/auth/login');
        }
    };

    return(
        <Modal isOpen={isOpen} toggle={toggle} size="xl" centered>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={toggle}><strong>Crear Persona</strong></ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup className={getControlState('firstName')}>
                                <Label>Nombres*</Label>
                                <input
                                    type="text"
                                    name="firstName"
                                    className="form-control"
                                    ref={register}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup className={getControlState('lastName')}>
                                <Label>Apellidos*</Label>
                                <input
                                    type="text"
                                    name="lastName"
                                    className="form-control"
                                    ref={register}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup className={`has-label ${documentTypeSelected ? 'has-success' : ''}`}>
                                <Label>Tipo de Documento*</Label>
                                <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    placeholder="Seleccione un Tipo de documento"
                                    name="stateSelect"
                                    options={documentTypeList}
                                    value={documentTypeSelected}
                                    getOptionLabel={option => `${option.name}`}
                                    getOptionValue={option => `${option.id}`}
                                    noOptionsMessage={() => null}
                                    onChange={handleDocumentTypeSelect}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup className={getControlState('documentNumber')}>
                                <Label>Identificación*</Label>
                                <input
                                    type="number"
                                    name="documentNumber"
                                    className="form-control"
                                    defaultValue={defaultValue?.documentNumber}
                                    ref={register}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup className={`has-label ${genderSelected ? 'has-success' : ''}`}>
                                <Label>Género*</Label>
                                <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    placeholder="Seleccione género de la persona"
                                    name="gender"
                                    options={genderList}
                                    value={genderSelected}
                                    getOptionLabel={option => `${option.name}`}
                                    getOptionValue={option => `${option.id}`}
                                    noOptionsMessage={() => null}
                                    onChange={handleGender}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup className={getControlState('phone')}>
                                <Label>Telefono*</Label>
                                <input
                                    type="number"
                                    name="phone"
                                    className="form-control"
                                    ref={register}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup className={`has-label ${stateSelected ? 'has-success' : ''}`}>
                                <Label>Departamento*</Label>
                                <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    placeholder="Seleccione un departamento"
                                    name="stateSelect"
                                    options={stateList}
                                    value={stateSelected}
                                    getOptionLabel={option => `${option.name}`}
                                    getOptionValue={option => `${option.id}`}
                                    noOptionsMessage={() => null}
                                    onChange={handleStateSelect}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup className={`has-label ${citySelected ? 'has-success' : ''}`}>
                                <Label>Ciudad*</Label>
                                <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    placeholder="Seleccione un municipio"
                                    name="citySelect"
                                    options={getStateCities(stateSelected?.id)}
                                    getOptionLabel={option => `${option.name}`}
                                    getOptionValue={option => `${option.id}`}
                                    noOptionsMessage={() => null}
                                    value={citySelected}
                                    onChange={(value) => setCitySelected(value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup className={getControlState('address')}>
                                <Label>Dirección*</Label>
                                <input
                                    type="text"
                                    name="address"
                                    className="form-control"
                                    ref={register}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup className={getControlState('email')}>
                                <Label>Email*</Label>
                                <input
                                    type="text"
                                    name="email"
                                    className="form-control"
                                    ref={register}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Col xs={12} md={12}>
                        <Button
                            color="danger"
                            className="btn-round float-right"
                            type="submit"
                            disabled={!formState.isValid || !genderSelected || !citySelected }
                        >Guardar</Button>
                    </Col>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

export default PersonModal;
