import React, { useState, useEffect } from 'react';
import {useApi, useToast} from "../../hooks";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Form,
    Row,
    Col,
    Input,
    Label
} from 'reactstrap';
import Select from "react-select";
import { useForm } from "react-hook-form";
import * as yup from 'yup';

const schema = yup.object().shape({ user: yup.string().required() });

function ModalEditUser ({modalOpen, setModalOpen, listUsers, setListUser, currentUser, setCurrentUser}) {

    const { fetcher } = useApi();
    const toast = useToast();

    const [usersTypes, setUsersTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [privileges, setPrivileges] = useState([]);

    const { formState, handleSubmit, register, errors } = useForm({ mode: 'onBlur', validationSchema: schema });

    useEffect(() => {
        getUsersType();
        getPrivileges();
    }, []);

    useEffect(() => {
        if(currentUser){
            setSelectedType(currentUser.type.id);
        }
    }, [currentUser]);

    useEffect(() => {
        if(selectedType){
            if (selectedType !== currentUser.type.id)
                getPrivilegesUserType(false);
            else
                getPrivilegesUserType(true);
        }
    }, [selectedType]);

    const getUsersType = async () => {
        const {response, error} = await fetcher('GET','usertype',{});
        if (response){
            const {data, statusText} = response;
            if (statusText === "OK")
                setUsersTypes(data.data);
        }else if (error)
            console.log(error)
    }

    const getPrivileges = async () =>{
        const {response, error} = await fetcher('GET',`privilege`,{});
        if (response){
            const {data, statusText} = response;
            if (statusText === "OK")
                setPrivileges(data.data.map(item => ({
                    ...item,
                    checked: false
                })));
        }else if (error)
            console.log(error)

    }

    const getPrivilegesUserType = async (byUser) => {
        const urlType = `usertype/${selectedType}/privileges`;
        const urlUser = `user/${currentUser.id}/privileges`;
        const {response, error} = await fetcher('GET',byUser ? urlUser: urlType);
        if (response){
            const {data, statusText} = response;
            if (statusText === "OK"){
                setPrivileges(prev => prev.map(privilege => {
                    if(data.data.find(item => item.id === privilege.id)) privilege.checked = true;
                    else privilege.checked = false;
                    return privilege;
                }));
            }
        }else if (error)
            console.log(error)
    }

    const getControlState = (name) => `has-label ${!formState.touched[name] ? '' : errors[name] ? 'has-danger' : 'has-success'}`

    const toggle = () => setModalOpen(!modalOpen);

    const onSubmit = (event) => {
        event.preventDefault();
        putUser();
    }

    const putUser = async () => {
        let data = {
            username: currentUser.username,
            personId: 1,
            userTypeId: selectedType,
            privileges: privileges.filter( function(item) { if (item.checked) return item; }).map(function(obj) {return obj.id})
        };
        const {response, error} = await fetcher('PUT',`user/${currentUser.id}`,data);
        if (response){
            const {data, statusText} = response;
            if (statusText === "OK"){
                let user = currentUser;
                let typeUser = usersTypes.find(obj => obj.id === selectedType);
                user.username = data.data.username;
                user.type = typeUser;
                let index = listUsers.findIndex(obj => obj.id === user.id);
                setListUser(prev => {
                    prev.splice(index, 1, user);
                    return prev;
                });
                toast.success(data.message);                
                setModalOpen(!modalOpen);
            }
        }else if (error)
            console.log(error);
    }

    const handleSelectUserType = event => { setSelectedType(event.id) };

    const onChangePrivileges = (event) => { addRemovePrivilege(event.target.value); }

    const addRemovePrivilege = (id) => {
        setPrivileges(prev => {
            const index = prev.findIndex(item => item.id === Number(id));
            return prev.map((item, key) => {
                if(key === index) item.checked = !item.checked;
                return item;
            } );
        });
    }

    return (
        <div>
            <Modal isOpen={modalOpen} toggle={toggle} size="xl" centered>
                <ModalHeader toggle={toggle}>Actualizar Usuario</ModalHeader>
                <Form onSubmit={onSubmit}>
                    <ModalBody>
                        <Row>
                            <Col xs={6} md={6}>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <h6><strong>Información de usuario</strong></h6>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <FormGroup className={getControlState('profile')}>
                                            <label><strong>Perfil</strong></label>
                                            <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            placeholder="Seleccione un perfil"
                                            name="singleSelect"
                                            value={ usersTypes.filter(obj => obj.id === selectedType)}
                                            options={usersTypes}
                                            getOptionLabel={option => `${option.name}`}
                                            getOptionValue={option => `${option.id}`}
                                            noOptionsMessage={() => null}
                                            onChange={handleSelectUserType}/>  
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <FormGroup className={getControlState('user')}>
                                            <label><strong>Usuario</strong></label>
                                            <input
                                                type="text"
                                                name="user"
                                                className="form-control"
                                                defaultValue={currentUser?.username}
                                                onChange={ e => {
                                                    let user = currentUser;
                                                    user.username = e.target.value;
                                                    setCurrentUser(user);
                                                }}
                                                ref={register}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                
                            </Col>
                            <Col xs={6} md={6}>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <strong><h6>Permisos de usuario</h6></strong>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <Row>
                                        {
                                            privileges.map((prop, key) => {
                                                return(
                                                    <Col xs={6} md={6} key={key}>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input 
                                                                type="checkbox" 
                                                                value={prop.id}
                                                                onChange={onChangePrivileges}
                                                                checked={prop.checked}/>
                                                                <span className="form-check-sign" />
                                                                {prop.name}
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                )
                                            })
                                        } 
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col xs={12} md={12}>
                            <Button color="success" className="btn-round float-right" type="submit" disabled={!formState.isValid}>Actualizar</Button>
                        </Col>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
}

export default ModalEditUser;