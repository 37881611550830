import React, { useEffect, useState } from "react";
import {useApi, useEvents, useLocations} from "../../hooks";
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import Select from "react-select";
import ReactTable from 'react-table-v6';
import { PanelHeader } from "../../components";
import ModalCreateZone from './ModalCreateZone.view';
import ModalEditZone from './ModalEditZone.view';
import ModalDeleteZone from './ModaDeleteZone.view';

function Zone(){

    const { fetcher } = useApi();
    const { selectedEvent, locationList, locationsEvent } = useLocations();

    const [ zones, setZones ] = useState([]);
    const [ places, setPlaces ] = useState([]);
    const [ place, setPlace ] = useState(null);
    const [ zone, setZone ] = useState();
    const [ openModalCreate, setOpenModalCreate] = useState(false);
    const [ openModalEdit, setOpenModalEdit] = useState(false);
    const [ openModalDelete, setOpenModalDelete] = useState(false);

    useEffect(() => {
        if (place)
            getZones();
    }, [place]);

    useEffect(() => {
        setPlaces(locationList.filter(location => locationsEvent.find(sel => sel.locationId === location.id)));
    }, [locationsEvent]);

    useEffect(() => {
        setPlace(null);
        setZones([]);
    }, [places]);

    const getZones = async () => {
        const {response, error} = await fetcher('GET',`event/${selectedEvent}/place/${place.id}/zone`);
        if (response){
            const {data, statusText} = response;
            if (statusText === "OK"){
                setZones(data.data);
            }
        }else if (error)
            console.log(error)
    }

    const renderTable = (response_data) => {
        let data = response_data.map((prop, key) => {
            return {
                id: prop.id,
                locality: prop.place.name,
                name: prop.name,
                capacity: prop.capacity,
                actions: (
                    <div className="actions-right" id={`update_${key}`}>
                        <UncontrolledTooltip
                            placement="top"
                            delay={{show: 50, hide: 0}}
                            target={`button_${key}`}
                            key={key + '_tooltip'}>Actualizar</UncontrolledTooltip>
                        <UncontrolledTooltip
                            placement="top"
                            delay={{show: 50, hide: 0}}
                            target={`button_delete_${key}`}
                            key={key + '_tooltip_delete'}>Eliminar</UncontrolledTooltip>
                        <Button
                            id={`button_${key}`}
                            onClick={() => {
                                setZone(prop);
                                setOpenModalEdit(true);
                            }}
                            className="btn-icon btn-round"
                            color="warning"
                            size="sm">
                            <i className="fa fa-edit" />
                        </Button>{" "}
                        <Button
                            id={`button_delete_${key}`}
                            onClick={() => {
                                setZone(prop);
                                setOpenModalDelete(true)
                            }}
                            className="btn-icon btn-round"
                            color="danger"
                            size="sm">
                            <i className="fa fa-times" />
                        </Button>{" "}
                    </div>
                )
            };
        })
        return data;
    }

    const handleSelectPlace = value =>{
        setPlace(value);
    }

    return(
        <>
            <PanelHeader size="sm"/>
            <div className="content">
                <Card>
                    <CardHeader>
                    <Row>
                        <Col xs={12} md={6}>
                        </Col>
                    </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                <Row>
                                    <Col xs={12} md={10}>
                                        <CardTitle tag="h4"><strong>Lista de zonas</strong></CardTitle>
                                      
                                        <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        placeholder="Seleccione una localidad"
                                        name="singleSelect"
                                        options={places}
                                        value={place}
                                        getOptionLabel={option => `${option.name}`}
                                        getOptionValue={option => `${option.id}`}
                                        noOptionsMessage={() => null}
                                        onChange={handleSelectPlace}/>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <Button
                                            onClick={()=>setOpenModalCreate(true)}
                                            color="success" className="btn-round float-right">Agregar Zona</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs={12} md={12}>
                                <ReactTable
                                    data={renderTable(zones)}
                                    filterable
                                    columns={[
                                        {
                                            Header: "Localidad",
                                            accessor: "locality"
                                        },
                                        {
                                            Header: "Zona",
                                            accessor: "name"
                                        },
                                        {
                                            Header: "Capacidad",
                                            accessor: "capacity"
                                        },
                                        {
                                            Header: "Acciones",
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false
                                        }
                                    ]}
                                    previousText='Anterior'
                                    nextText='Siguiente'
                                    pageText='Página'
                                    ofText='de'
                                    rowsText='Filas'
                                    showPaginationBottom
                                    minRows={1}
                                    defaultPageSize={18}
                                    pageSizeOptions={[10, 15, 18, 20, 25, 50]}
                                    className="-striped -highlight"/>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <ModalCreateZone modalOpen={openModalCreate} setModalOpen={setOpenModalCreate} places={places} listZones={zones} setListZones={setZones} currentPlace={place}/>
                <ModalEditZone modalOpen={openModalEdit} setModalOpen={setOpenModalEdit} currentZone={zone} setCurrentZone={setZone} listZones={zones} setListZones={setZones}/>
                <ModalDeleteZone modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} currentZone={zone} listZones={zones} setListZones={setZones}/>
            </div>
        </>
    );
}

export default Zone;