import React from 'react';
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import ReactTable from "react-table-v6";

function LocationView({ data, openModal, setSelected, selectedLocations, addLocationEvent, selectedEvent }) {


    const mapDataActions = (data) => (
        // data.filter(location => !selectedEvents.includes(location.id)).map((item, key) => ({
        data.filter(location => !selectedLocations.find(sel => sel.locationId === location.id)).map((item, key) => ({
            ...item,
            actions: (
                <div className="actions-right" id={key}>
                    <Button
                        key={key}
                        className="btn-icon btn-round"
                        color="warning"
                        size="sm"
                        onClick={() => {
                            setSelected(item);
                            openModal(true);
                        }}
                    >
                        <i className="fa fa-edit" />
                    </Button>
                    <Button
                        key={`leave_${key}`}
                        className="btn-icon btn-round"
                        color="info"
                        size="sm"
                        onClick={() => {
                            addLocationEvent({
                                placeId: item.id,
                                eventId: selectedEvent
                            });
                        }}
                    >
                        <i className="now-ui-icons arrows-1_minimal-right" />
                    </Button>
                </div>
            )
        }))
    );

    return(
        <Card>
            <CardHeader>
                <Row>
                    <Col xs={12} md={12}>
                        <Row>
                            <Col xs={6} md={6}>
                                <CardTitle tag="h4"><strong>Lista de locaciones</strong></CardTitle>
                            </Col>
                            <Col xs={6} md={6}>
                                <Button
                                    color="success"
                                    className="btn-round float-right"
                                    onClick={() => openModal(true)}
                                >
                                    <i className="now-ui-icons ui-1_simple-add" /> Nueva
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xs={12} md={12}>
                        <ReactTable
                            data={mapDataActions(data)}
                            columns={[
                                {
                                    Header: "ID",
                                    accessor: "id",
                                    show: false
                                },
                                {
                                    Header: "Nombre",
                                    accessor: "name"
                                },
                                {
                                    Header: "Latitud",
                                    accessor: "lat"
                                },
                                {
                                    Header: "Longitud",
                                    accessor: "lng"
                                },
                                {
                                    Header: "Acciones",
                                    accessor: "actions"
                                }
                            ]}
                            previousText='Anterior'
                            nextText='Siguiente'
                            pageText='Página'
                            ofText='de'
                            rowsText='Filas'
                            showPaginationBottom
                            minRows={1}
                            defaultPageSize={18}
                            pageSizeOptions={[10, 15, 18, 20, 25, 50]}
                            noDataText="No hay locaciones disponibles"
                            className="-striped -highlight"
                        />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default LocationView;
