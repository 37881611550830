import React from 'react';
import {useApi, useToast} from "../../hooks";
import {
    Button,
    Modal,
    ModalHeader,
    ModalFooter,
    Col
} from 'reactstrap';

function ModalDeleteUser ({modalOpen, setModalOpen, currentUser, setListUser, listUsers, newStatus}) {

  const { fetcher } = useApi();
  const toast = useToast();
  const toggle = () => setModalOpen(!modalOpen);

  const deleteUser = async () => {
    let data = {
      id: currentUser.id,
      status: newStatus
    };
    const {response, error} = await fetcher('POST',`user/hide`, data);
    if (response){
        const {data, statusText} = response;
        if (statusText === "OK"){
          let list = listUsers;
          let index = list.findIndex(obj=> obj.id === currentUser.id);
          if (newStatus === 'OCULTO'){
            listUsers.splice(index,1);
          }else{
            let user = currentUser;
            user.status = newStatus;
            listUsers.splice(index, 1, user);
          }
          setListUser(list);
          toast.success(data.message);
          setModalOpen(!modalOpen);
        }else
          setModalOpen(!modalOpen);
    }else if (error)
      setModalOpen(!modalOpen);
  }

  const renderText = () =>{
    if (newStatus === 'ACTIVO')
      return (<span>Activar Usuario</span>)
    else if (newStatus ==='INACTIVO')
      return (<span>Desactivar Usuario</span>)
    else if (newStatus ==='OCULTO')
      return (<span>Eliminar Usuario</span>)
  }

  return (
      <div>
    
          <Modal  isOpen={modalOpen} toggle={toggle} centered>
          <ModalHeader toggle={toggle}><strong>{renderText()}</strong></ModalHeader>
            <hr></hr>
          <ModalFooter>
            <Col xs={12} md={12}>
              <Button color="danger" className="btn-round float-right" onClick={() => deleteUser()}>{renderText()}</Button>
            </Col>
          </ModalFooter>
        </Modal>
      </div>
  );
}

export default ModalDeleteUser;