import React from 'react';
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import ReactTable from "react-table-v6";

function LocationSelectedView({ data, selectedLocations, openModal, setSelected, removeLocationEvent }) {

    const mapData = (data) => {
        // return data.filter(location => selectedEvents.includes(location.id)).map((item, key) => ({
        return data.filter(location => selectedLocations.find(sel => sel.locationId === location.id)).map((item, key) => ({
            ...item,
            actions: (
                <div className="actions-right" id={key}>
                    <Button
                        key={`upd_${key}`}
                        className="btn-icon btn-round"
                        color="warning"
                        size="sm"
                        onClick={() => {
                            setSelected(item);
                            openModal(true);
                        }}
                    >
                        <i className="fa fa-edit" />
                    </Button>
                    <Button
                        key={`leave_${key}`}
                        className="btn-icon btn-round"
                        color="danger"
                        size="sm"
                        onClick={() => removeLocationEvent(selectedLocations.find(sel => sel.locationId === item.id).id)}
                    >
                        <i className="now-ui-icons arrows-1_minimal-left" />
                    </Button>
                </div>
            )
        }))
    };

    return(
        <Card>
            <CardHeader>
                <CardTitle tag="h4"><strong>Locaciones en evento</strong></CardTitle>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xs={12} md={12}>
                        <ReactTable
                            data={mapData(data)}
                            columns={[
                                {
                                    Header: "ID",
                                    accessor: "id",
                                    show: false
                                },
                                {
                                    Header: "Nombre",
                                    accessor: "name"
                                },
                                {
                                    Header: "Latitud",
                                    accessor: "lat"
                                },
                                {
                                    Header: "Longitud",
                                    accessor: "lng"
                                },
                                {
                                    Header: "Acciones",
                                    accessor: "actions"
                                }
                            ]}
                            previousText='Anterior'
                            nextText='Siguiente'
                            pageText='Página'
                            ofText='de'
                            rowsText='Filas'
                            showPaginationBottom
                            minRows={1}
                            defaultPageSize={18}
                            pageSizeOptions={[10, 15, 18, 20, 25, 50]}
                            noDataText="No hay locaciones asignadas al evento"
                            className="-striped -highlight"/>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default LocationSelectedView;