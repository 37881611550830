import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import {Button, Col, Form, FormGroup, Label, Row, CardHeader, CardBody, CardFooter, Nav, NavItem, NavLink, TabContent, TabPane, Spinner } from "reactstrap";
import { useForm, FormContext, Controller } from "react-hook-form";
import * as yup from 'yup';
import Select from "react-select";
import { useApi, useToast, useEvents } from '../../hooks';
import { personSchema } from "../../const";
import { selectSchema, arraySchema } from "../../const/personSchema";
import {PersonForm} from "../../components";

const schema = yup.object().shape({
    name: yup.string().required(),
    documentType: selectSchema,
    documentNumber: yup.number().required(),
    email: yup.string().email().required(),
    nameManager: yup.string().required(),
    numberPhone: yup.number().required(),
    people: yup.array().of(personSchema)
});

function Partner () {

    const [ event, setEvent ] = useState(0);
    const [ category, setCategory ] = useState(0);
    const [ subCategoryList, setSubCategoryList ] = useState([]);
    const [ defaultValues, setDefaultValues ] = useState([]);
    const [ formIndexes, setFormIndexes ] = useState([]);
    const [ navSelected, setNav ] = useState(0);
    const [ categoryName, setCategoryName ] = useState('');
    const [ showLoading, setShowLoading ] = useState(false);
    const location = useLocation();

    const methods = useForm({
        mode: 'onBlur',
        validationSchema: schema
    });

    const { addPartnerEvent, fetcher, getSubcategories, getPartnerDocumentNumber, getPersonDocumentNumber } = useApi();
    const toast = useToast();

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const onSubmit = async (data) => {
        
        let people = [];
        if(data.people && data.people.length > 0){
            setShowLoading(true);
            for (let i = 0; i < data.people.length; i++) {
                const item = data.people[i];
                const photo = item.file? await toBase64(item.file):null;
                people.push({
                    academicTraining: item.academicTraining.value,
                    gender: item.gender.value,
                    bloodType: item.bloodType.value,
                    documentType: item.documentType.value,
                    cityId: item.city.id,
                    height: item.height,
                    dateOfBirth: item.dateOfBirth,
                    email: item.email,
                    address: item.address,
                    phone: item.phone,
                    documentNumber: item.documentNumber,
                    lastName: item.lastName,
                    firstName: item.firstName,
                    oldWorker:item.isWorked,
                    photo: photo
                });
            }

            const obj = {
                partner: {
                    documentType: data.documentType.value,
                    name: data.name,
                    documentNumber: data.documentNumber
                },
                partner_event: {
                    eventId: event,
                    // categoryId: category.id,
                    categoryId: category,
                    subcategoryId: data.subCategory.id,
                    nameManager: data.nameManager,
                    numberPhone: data.numberPhone,
                    email: data.email,
                    state: 'ESPERA'
                },
                persons: people
            };

            const {response} = await addPartnerEvent(obj);
            if (response) {
                const { data } = response;
                if(data.success) {
                    toast.success("Solicitud enviada correctamente ");
                    methods.reset();
                    setDefaultValues([]);
                } else toast.error(data.message);
            } else {
                toast.error("Error al Enviar información");
            }
            setShowLoading(false);

        }else{
            toast.error('Completa el formulario');
        }
        
    }; 

    const verifyDocumentNumber = async (event) => {
        const value = event.target.value;
        const {response, error} = await getPartnerDocumentNumber(value);
        if (response && response.statusText === 'OK') {
            const { data } = response;
            methods.setValue([
                {documentType: stateList.find(item => item.value === data.data.documentType)},
                {documentNumber: data.data.documentNumber},
                {name: data.data.name}
            ]);
        }
    };

    const verifyPersonDocumentNumber = async (event, index) => {
        const value = event.target.value;
        const { response } = await getPersonDocumentNumber(value);
        if (response && response.statusText === 'OK') {
            const { data } = response;
            setDefaultValues(prev => {
                const array = [...prev];
                array[index] = data.data;
                return array;
            });
        }
    };

    const getControlState = (name) => `has-label ${!methods.formState.touched[name] ? '' : methods.errors[name] ? 'has-danger' : 'has-success'}`;

    const handleSubCategory = ([selected]) => {
        const array = [];
        for (let i = 0; i < selected.quota; i++) {
            array.push(i);
        }
        setFormIndexes(array);
        return selected;
    };

    useEffect(() => {

        const getCategoryName = async (id) => {
            const { response } = await fetcher('get', `category/${id}`);
            if (response && response.statusText === 'OK') {
                const { data } = response;
                setCategoryName(data.data.name);
            }
        };

        const getCategories = async (id) => {
            const { response } = await getSubcategories(id);
            if (response && response.statusText === 'OK') {
                const { data } = response;
                setSubCategoryList(data.data);
            }
        };

        const paths = location.pathname.split('/');
        if(subCategoryList.length == 0){
            setEvent(Number(paths[4]));
            if(paths[6]) {
                setCategory(Number(paths[6]));
                getCategoryName(paths[6]).then();
                getCategories(paths[6]).then();
            }
        }
        
    }, [location]);

    return (
        <FormContext {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}> 
                <CardHeader>
                    Inscripción de {categoryName.toLowerCase()}
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup className={getControlState('documentType')}>
                                <Label>Tipo de Documento*</Label>
                                <Controller
                                    as={<Select
                                        options={stateList}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        placeholder="Seleccione un Tipo de documento"
                                        noOptionsMessage={() => null}
                                    />}
                                    name="documentType"
                                    control={methods.control}
                                    onChange={([selected]) => selected}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup className={getControlState('documentNumber')}>
                                <Label>Identificación (sin número de verificación)*</Label>
                                <input
                                    type="text"
                                    name="documentNumber"
                                    className="form-control"
                                    onBlur={verifyDocumentNumber}
                                    ref={methods.register}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup className={getControlState('name')}>
                                <Label>Nombre*</Label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    ref={methods.register}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup className={getControlState('subCategory')}>
                                <Label>Categoría*</Label>
                                <Controller
                                    as={<Select
                                        options={subCategoryList}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        placeholder="Seleccione categoría de la empresa"
                                        getOptionLabel={option => `${option.name}`}
                                        getOptionValue={option => `${option.id}`}
                                        noOptionsMessage={() => null}
                                    />}
                                    name="subCategory"
                                    control={methods.control}
                                    onChange={handleSubCategory}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup className={getControlState('nameManager')}>
                                <Label>Nombre del responsable o representante*</Label>
                                <input
                                    type="text"
                                    name="nameManager"
                                    className="form-control"
                                    ref={methods.register}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup className={getControlState('numberPhone')}>
                                <Label>Número de contacto del responsable o representante*</Label>
                                <input
                                    type="text"
                                    name="numberPhone"
                                    className="form-control"
                                    ref={methods.register}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <FormGroup className={getControlState('email')}>
                                <Label>Email*</Label>
                                <input
                                    type="text"
                                    name="email"
                                    className="form-control"
                                    ref={methods.register}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <Nav pills className="nav-pills-primary">
                                {formIndexes.map(index => (
                                    <NavItem key={`navTab${index}`}>
                                        <NavLink
                                            className={navSelected === index ? "active" : ""}
                                            onClick={() => setNav(index)}
                                        >
                                            Datos de Persona #{index + 1}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>    
                            
                            <TabContent activeTab={`nav${navSelected}`} className="tab-space">
                                {formIndexes.map(index => (
                                    <TabPane key={`tab${index}`} tabId={`nav${index}`}>
                                        <PersonForm
                                            inputWorked
                                            inputPhoto
                                            fieldName="people"
                                            index={index}
                                            defaultValue={defaultValues[index]}
                                            blurDocument={verifyPersonDocumentNumber}
                                        />
                                    </TabPane>
                                ))}
                            </TabContent>
                        </Col>
                    </Row>
                    
                </CardBody>
                <CardFooter>
                    <Col xs={12} md={12}>
                        <Button color="danger" className="btn-round" type="submit" disabled={!methods.formState.isValid || showLoading}>
                            {showLoading ? <><Spinner color="light" size="sm"/> Enviando...</> : 'Enviar Solicitud'} 
                        </Button>
                        
                    </Col>
                </CardFooter>
            </form>
            
        </FormContext>
    );
}

export default Partner;

const stateList = [
    {
        value: 'CC',
        label: 'Cédula de ciudadanía'
    },
    {
        value: 'NIT',
        label: 'NIT'
    },
    {
        value: 'INT',
        label: 'Documento internacional'
    },
];
