import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Form,
    Row,
    Col
} from 'reactstrap';
import {useApi, useToast} from "../../hooks";
import Select from "react-select";
import { useForm } from "react-hook-form";
import * as yup from 'yup';

const schema = yup.object().shape({
    name: yup.string().required(),
    capacity: yup.number().required()
});

function ModalCreateZone ({modalOpen, setModalOpen, listZones, setListZones, currentPlace}) {

    const { fetcher } = useApi();
    const toast = useToast();

    const [ name, setName ] = useState('');
    const [ capacity, setCapacity ] = useState('');

    const { formState, handleSubmit, register, errors } = useForm({
        mode: 'onBlur',
        validationSchema: schema
    });

    const getControlState = (name) => `has-label ${!formState.touched[name] ? '' : errors[name] ? 'has-danger' : 'has-success'}`

    const toggle = () => setModalOpen(!modalOpen);

    const onSubmit = (event) => {
        event.preventDefault();
        postZone();
    }

    const postZone = async () =>{
        let data = {
            eventId: 1,
            placeId: currentPlace.id,
            name: name,
            capacity: capacity
        };
        const {response, error} = await fetcher('POST',`zone`,data);
        if (response){
            const {data, statusText} = response;
            if (statusText === "OK"){
                toast.success(data.message);
                setModalOpen(!modalOpen);
            }
        }else if (error)
            console.log(error)
    }

    return (
        <div>
            <Modal isOpen={modalOpen} toggle={toggle} size="lg" centered>
                <ModalHeader toggle={toggle}><strong>Crear Zona</strong></ModalHeader>

                <Form onSubmit={onSubmit}>
                    <ModalBody>
                        <Row>
                            <Col xs={6} md={6}>
                                <FormGroup className={getControlState('name')}>
                                    <label>Nombre</label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        onChange={e => setName(e.target.value)}
                                        ref={register}/>
                                </FormGroup>
                            </Col>
                            <Col xs={6} md={6}>
                                <FormGroup className={getControlState('capacity')}>
                                    <label>Capacidad</label>
                                    <input
                                        type="text"
                                        name="capacity"
                                        className="form-control"
                                        onChange={e => setCapacity(e.target.value)}
                                        ref={register}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col xs={12} md={12}>
                            <Button color="primary" className="btn-round float-right" type="submit" disabled={!formState.isValid}>Guardar</Button>
                        </Col>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
}

export default ModalCreateZone;