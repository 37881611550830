import * as yup from "yup";

export const selectSchema = yup.object().shape({
    value: yup.string().required(),
    label: yup.string().required(),
});

export const arraySchema = yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
});

const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    documentType: selectSchema,
    documentNumber: yup.number().required(),
    phone: yup.number().required(),
    address: yup.string().required(),
    email: yup.string().email().required(),
    dateOfBirth: yup.string().required(),
    height: yup.number().min(0).required(),
    bloodType: selectSchema,
    gender: selectSchema,
    academicTraining: selectSchema,
    // cityId: citySchema,
});

export default schema;
