import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    Container
} from "reactstrap";

function AuthNavbar () {
    const [ isOpen, setOpen ] = useState(false);

    const toggle = () => {
        setOpen((prev) => !prev)
    };

    const activeRoute = routeName => {
        return window.location.href.indexOf(routeName) > -1 ? "active" : "";
    };

    return (
        <Navbar
            expand="lg"
            className={
                isOpen
                    ? "bg-white navbar-absolute"
                    : "navbar-transparent navbar-absolute"
            }
        >
            <Container fluid>
                <div className="navbar-wrapper">
                    <div className="navbar-toggle">
                        <NavbarToggler onClick={toggle}>
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </NavbarToggler>
                    </div>
                    <Link to="/" className="navbar-brand">
                        Fundación Festival de la Leyenda Vallenata
                    </Link>
                </div>
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem className={activeRoute("/auth/login")}>
                            <Link to="/auth/login" className="nav-link">
                                <i className="now-ui-icons users_circle-08" /> Inicio de sesión
                            </Link>
                        </NavItem>
                        <NavItem className={activeRoute("/auth/register")}>
                            <Link to="/auth/register-page" className="nav-link">
                                <i className="now-ui-icons tech_mobile" /> Registro
                            </Link>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default AuthNavbar;
