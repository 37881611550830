import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import NotificationAlert from "react-notification-alert";
import {useSelector, useDispatch} from "react-redux";
import {AdminNavbar, Footer, Sidebar} from "../../components";
import routes from "../../routes";
import {useApi, useEvents} from "../../hooks";
import { eventActions } from '../../../store/actions';
import ModalSelectEvent from "./ModalSelectEvent";

var ps;

function DashboardLayout () {

    const [ sidebarMini, setSidebarMini ] = useState(true);
    const [ isOpenModal, setIsOpenModal ] = useState(false);
    const [ backgroundColor, setBackgroundColor ] = useState('red');
    const { selectedEvent, eventList, changeSelected } = useEvents();
    const userPrivileges = useSelector(state => state.auth.user.privileges.map(item => item.id));

    const dispatch = useDispatch();
    const { getAllEvents } = useApi();

    const notificationAlert = useRef(null);
    const mainPanel = useRef(null);
    const history = useHistory();

    const toggleModal = () => setIsOpenModal(prevState => !prevState);

    const minimizeSidebar = () => {
        let message = "Sidebar mini ";
        if (document.body.classList.contains("sidebar-mini")) {
            setSidebarMini(false);
            message += "deactivated...";
        } else {
            setSidebarMini(true);
            message += "activated...";
        }
        document.body.classList.toggle("sidebar-mini");
        const options = {
            place: "tr",
            message: message,
            type: "info",
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 7
        };
        notificationAlert.current.notificationAlert(options);
    };

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (!prop.collapse) {
                if (prop.layout === '/admin' && validateRoute(prop.privileges, userPrivileges)) {
                    return(
                        <Route
                            path={prop.layout + prop.path}
                            component={prop.component}
                            key={key}
                        />
                    );
                }
            } else {
                return getRoutes(prop.views);
            }
        });
    };

    const validateRoute = (viewPrivileges, userPrivileges) => viewPrivileges[0] === 0 || viewPrivileges.filter(value => userPrivileges.includes(value)).length > 0;

    useLayoutEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            ps = new PerfectScrollbar(mainPanel.current);
        }

        return () => {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.documentElement.className += " perfect-scrollbar-off";
                document.documentElement.classList.remove("perfect-scrollbar-on");
            }
        }
    }, [mainPanel]);

    useLayoutEffect(() => {
        if (history.action === "PUSH") {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            mainPanel.current.scrollTop = 0;
        }
    }, [history]);

    useEffect(() => {
        async function fetchEvents() {
            const { response, error } = await getAllEvents();
            if (response) {
                const { data } = response;
                dispatch(eventActions.setEventList(data.data));
            } else if (error) {
                console.log(error.message);
                if (error.message.search('401') > 0) history.push('/auth/login');
            }
        }
        fetchEvents().then();
    }, []);

    useEffect(() => {
        let timeOut;
        if(!selectedEvent) timeOut = setTimeout(() => setIsOpenModal(true), 2000);

        return () => {
            if (timeOut) clearTimeout(timeOut);
        }
    }, [selectedEvent]);

    return(
        <div className="wrapper">
            <NotificationAlert ref={notificationAlert} />
            <Sidebar
                minimizeSidebar={minimizeSidebar}
                backgroundColor={backgroundColor}
                routes={routes}
                userPrivileges={userPrivileges}
            />
            <div className="main-panel" ref={mainPanel}>
                <AdminNavbar
                    brandText="Fundación Festival de la leyenda Vallenata"
                    selectedEvent={selectedEvent}
                    eventList={eventList}
                    toggleSelected={changeSelected}
                />
                <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/admin" to="/admin/index" />
                </Switch>
                {/*// we don't want the Footer to be rendered on full screen maps page
                    window.location.href.indexOf("full-screen-maps") !== -1 ? null : (
                        <Footer fluid />
                    )
                */}
            </div>
            <ModalSelectEvent isOpen={isOpenModal} toggle={toggleModal} eventList={eventList} toggleSelected={changeSelected} />
        </div>
    );
}

export default DashboardLayout;
