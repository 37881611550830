import React, { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useApi, useToast } from "../../hooks";
import { useDispatch } from "react-redux";
import authActions from '../../../store/actions/auth';
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Form,
    Container,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button
} from "reactstrap";

// core components
import fvLogo from "../../assets/img/logo-big.png";
import bgImage from "../../assets/img/login-bg.jpg";

function Login() {

    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ usernameFocus, setUsernameFocus ] = useState(false);
    const [ passwordFocus, setPasswordFocus ] = useState(false);
    const [ logging, setLogging ] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const toast = useToast();
    const { authUser } = useApi();

    const onSubmit = async (event) => {
        event.preventDefault();
        setLogging(true);
        const { response, error } = await authUser({username, password});
        if (response) {
            const { data } = response;
            dispatch(authActions.loginSuccess(data.data));
            toast.success(data.message);
            history.push('/admin');
        } else if (error) {
            if (error.message.search('401') > 0) toast.error('Usuario o contraseña incorrecta');
            else toast.error('Parece que hubo un problema, contacte a soporte.');
            setLogging(false);
        }
    };

    useLayoutEffect(() => {
        document.body.classList.add("login-page");
        dispatch(authActions.logout());
        return () => document.body.classList.remove("login-page");
    }, []);

    return (
        <>
            <div className="content">
                <div className="login-page">
                    <Container>
                        <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                            <Form onSubmit={onSubmit}>
                                <Card className="card-login card-plain">
                                    <CardHeader>
                                        <div className="logo-container">
                                            <img src={fvLogo} alt="now-logo" />
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <InputGroup
                                            className={
                                                "no-border form-control-lg " +
                                                (usernameFocus ? "input-group-focus" : "")
                                            }
                                        >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="now-ui-icons users_circle-08" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type="text"
                                                placeholder="Nombre de usuario"
                                                onFocus={e => setUsernameFocus(true)}
                                                onBlur={e => setUsernameFocus(false)}
                                                onChange={e => setUsername(e.target.value)}
                                            />
                                        </InputGroup>
                                        <InputGroup
                                            className={
                                                "no-border form-control-lg " +
                                                (passwordFocus ? "input-group-focus" : "")
                                            }
                                        >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="now-ui-icons objects_key-25" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type="password"
                                                placeholder="Contraseña"
                                                onFocus={e => setPasswordFocus(true)}
                                                onBlur={e => setPasswordFocus(false)}
                                                onChange={e => setPassword(e.target.value)}
                                            />
                                        </InputGroup>
                                    </CardBody>
                                    <CardFooter>
                                        <Button
                                            block
                                            type="submit"
                                            color="danger"
                                            size="lg"
                                            className="mb-3 btn-round"
                                            disabled={username === '' || password === '' || logging}
                                        >
                                            Iniciar Sesión
                                        </Button>
                                        <div className="pull-left" />
                                        <div className="pull-right">
                                            <h6>
                                                <a href="#pablo" className="link footer-link">
                                                    ¿Necesitas ayuda?
                                                </a>
                                            </h6>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Container>
                </div>
            </div>
            <div
                className="full-page-background"
                style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
        </>
    );
}

export default Login;
