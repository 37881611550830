import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Form,
    Col,
    Row
} from 'reactstrap';
import {useApi, useToast} from "../../hooks";
import { useForm } from "react-hook-form";
import * as yup from 'yup';


const schema = yup.object().shape({
    name: yup.string().required(),
    locality: yup.string().required(),
    capacity: yup.number().required().integer(),
});

function ModalEditZone ({modalOpen, setModalOpen, currentZone, setCurrentZone, listZones, setListZones}) {

    const { fetcher } = useApi();
    const toast = useToast();

    const { formState, handleSubmit, register, errors } = useForm({
        mode: 'onBlur',
        validationSchema: schema
    });

    const [name, setName] = useState('');
    const [capacity, setCapacity] = useState('');

    useEffect(() => {
        if (currentZone){
            setName(currentZone.name);
            setCapacity(currentZone.capacity);
        }
    }, [currentZone]);

    const getControlState = (name) => `has-label ${!formState.touched[name] ? '' : errors[name] ? 'has-danger' : 'has-success'}`

    const toggle = () => setModalOpen(!modalOpen);

    const onSubmit = (event) => {
        event.preventDefault();
        putZone();
    }

    const putZone = async () =>{
        let data = {
            name: name,
            capacity: capacity,
            eventId: currentZone.eventId,
            placeId: currentZone.placeId
        };
        const {response, error} = await fetcher('PUT',`zone/${currentZone.id}`,data);
        if (response){
            console.log(response);
            const {data, statusText} = response;
            if (statusText === "OK"){
                let zones = listZones;
                let index = zones.findIndex(obj=> obj.id === currentZone.id);
                zones[index].name = name;
                zones[index].capacity = capacity;
                setListZones(zones);
                toast.success(data.message);
                setModalOpen(!modalOpen);
            }
        }else if (error)
            console.log(error)
    }

    return (
        <div>
            <Modal isOpen={modalOpen} toggle={toggle} size="lg" centered>
                <ModalHeader toggle={toggle}>Actualizar Zona</ModalHeader>
                <Form onSubmit={onSubmit}>
                    <ModalBody>
                        <Row>
                            <Col xs={6} md={6}>
                                <FormGroup className={getControlState('name')}>
                                    <label>Nombre</label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        value={name}
                                        onChange={ e => setName(e.target.value) }
                                        ref={register}/>
                                </FormGroup>
                            </Col>
                            <Col xs={6} md={6}>
                                <FormGroup className={getControlState('capacity')}>
                                    <label>Capacidad</label>
                                    <input
                                        type="text"
                                        name="capacity"
                                        className="form-control"
                                        value={capacity}
                                        onChange={ e => setCapacity(e.target.value) }
                                        ref={register}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col xs={12} md={12}>
                            <Button color="success" className="btn-round float-right" type="submit">Actualizar</Button>
                        </Col>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
}

export default ModalEditZone;