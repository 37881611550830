import React, { useState } from 'react';
import {useApi, useToast} from "../../hooks";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Col
} from 'reactstrap';

function ModalDeleteZone ({modalOpen, setModalOpen, currentZone, listZones, setListZones}) {

    const toggle = () => setModalOpen(!modalOpen);
    const { fetcher } = useApi();
    const toast = useToast();

    const deleteZone = async () => {
        const {response, error} = await fetcher('DELETE',`zone/${currentZone.id}`,{});
        console.log(response);
        if (response){
            const {data, statusText} = response;
            if (statusText === "OK"){
                let zones = listZones;
                let index = zones.findIndex(obj=> obj.id === currentZone.id);
                listZones.splice(index,1);
                setListZones(listZones);
                toast.success(data.message);
                setModalOpen(!modalOpen);
            }
        }else if (error)
            console.log(error)
    }

    return (
        <div>
            <Modal isOpen={modalOpen} toggle={toggle} centered>
                <ModalHeader toggle={toggle}><strong>Eliminar Zona</strong></ModalHeader>
                <ModalBody>
                    <h4>Seguro desea eliminar la zona: <strong>{currentZone?.name}</strong></h4>
                </ModalBody>
                <ModalFooter>
                    <Col xs={12} md={12}>
                        <Button onClick={ () => deleteZone() } color="danger" className="btn-round float-right">Eliminar</Button>
                    </Col>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ModalDeleteZone;
