import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session';
import storageLocal from 'redux-persist/lib/storage';
import createEncryptor from 'redux-persist-transform-encrypt';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import auth from './auth';
import events from './events';
import locations from './locations';
import geography from './geography';

const encryptor = createEncryptor({
    secretKey: 'backoffice_makingID',
    onError: function (error) {
        console.log(`Parece que hubo un error: ${error.messsage}`);
    }
});

const rootPersistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['auth'],
    transforms: [encryptor],
    stateReconciler: autoMergeLevel2
};

const eventPersistConfig = {
    key: 'events',
    storage: storageLocal,
    whitelist: ['selected'],
    transforms: [encryptor],
    stateReconciler: autoMergeLevel2
};

const geographyPersistConfig = {
    key: 'geography',
    storage: storageLocal,
    whitelist: ['states', 'cities'],
    transforms: [encryptor],
    stateReconciler: autoMergeLevel2
};

const rootReducer = combineReducers({
    auth,
    events: persistReducer(eventPersistConfig, events),
    locations,
    geography: persistReducer(geographyPersistConfig, geography),
});

export default persistReducer(rootPersistConfig, rootReducer);
