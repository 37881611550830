import {
    Login,
    Index,
    Locality,
    Zone,
    Events,
    User,
    UserTypes,
    UserAudit,
    Partner,
    Accreditation,
    StaffPerson
} from "./views";


const routes = [
    {
        collapse: false,
        sidebar: false,
        name: 'Inicia Sesión',
        component: Login,
        layout: '/auth',
        path: '/login',
        privileges: [0],
    },
    {
        collapse: false,
        sidebar: false,
        name: 'Registrar Empresa',
        component: Partner,
        layout: '/clean',
        path: '/partner',
        privileges: [0],
    },
    {
        collapse: false,
        sidebar: false,
        name: 'Registrar personal de logistica',
        component: StaffPerson,
        layout: '/clean',
        path: '/staffPerson',
        privileges: [0],
    },
    {
        collapse: false,
        sidebar: true,
        name: 'Inicio',
        icon: 'now-ui-icons design_app',
        component: Index,
        layout: '/admin',
        path: '/index',
        privileges: [4],
    },
    {
        collapse: true,
        name: 'Usuarios',
        state: 'openUsers',
        icon: "now-ui-icons users_single-02",
        views: [
            {
                sidebar: true,
                path: "/user",
                name: "Usuarios",
                mini: "US",
                component: User,
                layout: "/admin",
                privileges: [1],
            },
            {
                sidebar: true,
                path: "/type_user",
                name: "Tipos de Usuarios",
                mini: "UT",
                component: UserTypes,
                layout: "/admin",
                privileges: [2],
            },
            {
                sidebar: true,
                path: "/audit_user",
                name: "Auditoría de Usuarios",
                mini: "AU",
                component: UserAudit,
                layout: "/admin",
                privileges: [3],
            },
        ]
    },
    {
        collapse: true,
        name: 'Acreditación',
        state: 'openAccreditation',
        icon: "now-ui-icons business_badge",
        views: [
            {
                sidebar: true,
                path: "/locality",
                name: "Localidad",
                mini: "LO",
                component: Locality,
                layout: "/admin",
                privileges: [20],
            },
            {
                sidebar: true,
                path: "/zone",
                name: "Zonas",
                mini: "ZO",
                component: Zone,
                layout: "/admin",
                privileges: [21],
            },

            {
                sidebar: true,
                path: "/events",
                name: "Eventos",
                mini: "EV",
                component: Events,
                layout: "/admin",
                privileges: [22],
            },
            {
                sidebar: true,
                path: "/staff",
                name: "Personal Asistente",
                mini: "PA",
                component: Accreditation,
                layout: "/admin",
                privileges: [7, 9, 10, 11],
            },
            {
                sidebar: true,
                path: "/profile",
                name: "Perfiles",
                mini: "PE",
                component: Locality,
                layout: "/admin",
                privileges: [5],
            },
            {
                sidebar: true,
                path: "/vehicle",
                name: "Vehículos",
                mini: "VE",
                component: Locality,
                layout: "/admin",
                privileges: [12],
            },
            {
                sidebar: true,
                path: "/partner",
                name: "Patrocinadores",
                mini: "PT",
                component: Locality,
                layout: "/admin",
                privileges: [6, 8],
            },
            {
                sidebar: true,
                path: "/report",
                name: "Reportes",
                mini: "RE",
                component: Locality,
                layout: "/admin",
                privileges: [13],
            }
        ]
    },
    {
        collapse: true,
        name: 'Concursos',
        state: 'openContest',
        icon: "now-ui-icons objects_globe",
        views: [
            {
                sidebar: true,
                path: "/contest",
                name: "Concursos",
                mini: "CO",
                component: Locality,
                layout: "/admin",
                privileges: [14],
            },
            {
                sidebar: true,
                path: "/jury",
                name: "Jurado",
                mini: "JU",
                component: Locality,
                layout: "/admin",
                privileges: [15],
            },
            {
                sidebar: true,
                path: "/competitor",
                name: "Inscritos",
                mini: "IN",
                component: Locality,
                layout: "/admin",
                privileges: [16, 17, 18],
            },
            {
                sidebar: true,
                path: "/unpublished-pre",
                name: "Preselección Canción Inédita",
                mini: "PS",
                component: Locality,
                layout: "/admin",
                privileges: [19],
            },
        ]
    }
];

export default routes;
