import React, { useState, useLayoutEffect, useRef } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import routes from '../routes';
import {AuthNavbar, Footer, FixedPlugin} from "../components";
import PerfectScrollbar from "perfect-scrollbar";
import { Card } from 'reactstrap';

var ps;

function CleanLayout (props) {

    const [ filterColor, setFilterColor ] = useState('yellow');
    const fullPages = useRef(null);

    const handleColorClick = color => {
        setFilterColor(color);
    };

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (!prop.collapse) {
                if (prop.layout === '/clean') {
                    return(
                        <Route
                            path={prop.layout + prop.path}
                            component={prop.component}
                            key={key}
                        />
                    );
                }
            } else {
                return getRoutes(prop.views);
            }
        });
    };

    useLayoutEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(fullPages.current);
        }

        return () => {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
        }
    }, []);

    return(
        <>
            <div className="wrapper-consuelo" ref={fullPages}>
                <Card>
                    <Switch>
                        { getRoutes(routes) }
                    </Switch>
                </Card>
            </div>
        </>
    );
}

export default CleanLayout;
