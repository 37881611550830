import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {useApi, useEvents} from "./index";
import {locationActions} from "../../store/actions";

function useLocations() {
    // const fetched
    const dispatch = useDispatch();
    const locationList = useSelector(store => store.locations.list);
    const locationsEvent = useSelector(store => store.locations.selectedList);
    const history = useHistory();
    const { getAllLocations, getAllEventLocations, insertLocation, updateLocation, insertLocationEvent, deleteLocationEvent } = useApi();
    const { selectedEvent } = useEvents();

    const addLocation = async (location) => {
        const { response, error } = await insertLocation(location);
        if (response) {
            const { data } = response;
            dispatch(locationActions.pushLocation(data.data));
        } else if (error) {
            if (error.message.search('401') > 0) history.push('/auth/login');
        }
    };

    const updateLocationInList = async (id, location) => {
        const { response, error } = await updateLocation(id, location);
        if (response) {
            const { data } = response;
            dispatch(locationActions.updateLocation(data.data));
        } else if (error) {
            if (error.message.search('401') > 0) history.push('/auth/login');
        }
    };

    const addLocationEvent = async (location) => {
        const { response, error } = await insertLocationEvent(location);
        if (response) {
            const { data } = response;
            dispatch(locationActions.pushSelectedLocation(data.data));
        } else if (error) {
            if (error.message.search('401') > 0) history.push('/auth/login');
        }
        // dispatch(locationActions.pushSelectedLocation(id));
    };

    const removeLocationEvent = async (id) => {
        const { response, error } = await deleteLocationEvent(id);
        if (response) {
            const { data } = response;
            dispatch(locationActions.removeSelectedLocation(data.data.id));
        } else if (error) {
            if (error.message.search('401') > 0) history.push('/auth/login');
        }
    };

    useEffect(() => {
        const fetchAllLocations = async () => {
            const { response, error } = await getAllLocations();
            if (response) {
                const { data } = response;
                dispatch(locationActions.setLocationList(data.data));
            } else if (error) {
                if (error.message.search('401') > 0) history.push('/auth/login');
            }
        };

        if(locationList.length === 0) fetchAllLocations().then();
    }, [locationList]);

    useEffect(() => {
        const fetchAllEventLocations = async () => {
            const { response, error } = await getAllEventLocations(selectedEvent);
            if (response) {
                const { data } = response;
                dispatch(locationActions.setSelectedLocationList(data.data.map(item => ({
                    id: item.id,
                    locationId: item.placeId
                }))));
            } else if (error) {
                if (error.message.search('401') > 0) history.push('/auth/login');
            }
        };

        fetchAllEventLocations().then();
    }, [selectedEvent]);

    return { selectedEvent, locationList, locationsEvent, addLocation, updateLocationInList, addLocationEvent, removeLocationEvent };
}

export default useLocations;