import { useSelector } from 'react-redux';
import axios from 'axios';

function useApi() {

    const token = useSelector(state => state.auth.token);

    const fetcher = (type, url, data, contentType) => {

        const headers = {
            // 'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        headers['Content-Type'] = contentType || 'application/json';
        if(token) headers.Authorization = `Bearer ${token}`;
        const base_url = 'http://fflv-back.makingid.com/public/api/';
        return axios({
            method: type,
            url: `${base_url}${url}`,
            data: data ? data : {},
            headers
        }).then(response => ({response})).catch(error => ({error}));
    };

    const authUser = (user) => fetcher('post', 'login', user);

   
    const getAllEvents = () => fetcher('get', 'event');
    const insertEvent = (event) => fetcher('post', 'event', event);
    const updateEvent = (id, event) => fetcher('put', `event/${id}`, event);
    const getStates = () => fetcher('get', 'state');
    const getCities = () => fetcher('get', 'city');
    const getAllLocations = () => fetcher('get', 'place');
    const getAllEventLocations = (id) => fetcher('get', `event/${id}/places`);
    const insertLocation = (location) => fetcher('post', 'place', location);
    const updateLocation = (id, location) => fetcher('put', `place/${id}`, location);
    const insertLocationEvent = (location) => fetcher('post', `place_event`, location);
    const deleteLocationEvent = (id) => fetcher('delete', `place_event/${id}`);
    const addPartner = (partner) => fetcher('POST', 'partner', partner);
    const addPartnerEvent = (partner) => fetcher('POST', 'partner/event', partner);
    const addPersonEvent = (person) => fetcher('POST', 'person/event', person, 'multipart/form-data');
    const getUserTypes = () => fetcher('GET','usertype');
    const getPrivileges = () => fetcher('GET','privilege');
    const getUserTypePrivileges = (id) => fetcher('GET',`usertype/${id}/privileges`);
    const insertUserType = (userType) => fetcher('POST','usertype', userType);
    const updateUserType = (userType, id) => fetcher('PUT',`usertype/${id}`, userType);
    const getPersonDocumentNumber = (documentNumber) => fetcher('', `documentnumber/${documentNumber}/person`);
    const getPartnerDocumentNumber = (documentNumber) => fetcher('', `documentnumber/${documentNumber}/partner`);
    const insertPerson = (person) => fetcher('post', 'person', person);
    const getSubcategories = (id) => fetcher('get', `category/${id}/subcategories`);

    return {
        fetcher, authUser, getAllEvents, insertEvent, updateEvent, getAllLocations, getAllEventLocations,

        insertLocation, updateLocation, getStates, getCities, insertLocationEvent, deleteLocationEvent, addPartner,

        getUserTypes, getPrivileges, getUserTypePrivileges, insertUserType, updateUserType, getPersonDocumentNumber,

        insertPerson, addPersonEvent, addPartnerEvent, getPartnerDocumentNumber, getSubcategories
    }
}

export default useApi;
