import React, { useState } from "react";
import Switch from "react-bootstrap-switch";

function FixedPlugin ({ bgColor = 'blue', handleMiniClick = () => {}, sidebarMini = true, handleColorClick = () => {} }) {
    const [ classes, setClasses ] = useState('dropdown show');
    const [ bg_checked, setBg_checked ] = useState(true);
    const [ switched, setSwitched ] = useState(false);

    const handleClick = () => {
        if (classes === "dropdown") {
            setClasses('dropdown show');
        } else {
            setClasses('dropdown');
        }
    };

    const onMiniClick = () => {
        handleMiniClick();
    };

    return (
        <div className="fixed-plugin">
            <div className={classes}>
                <div onClick={handleClick}>
                    <i className="fa fa-cog fa-2x" />
                </div>
                <ul className="dropdown-menu show">
                    <li className="header-title">SIDEBAR BACKGROUND</li>
                    <li className="adjustments-line">
                        <div className="badge-colors text-center">
                <span
                    className={
                        bgColor === "yellow"
                            ? "badge filter badge-yellow active"
                            : "badge filter badge-yellow"
                    }
                    data-color="yellow"
                    onClick={() => {
                        handleColorClick("yellow");
                    }}
                />
                            <span
                                className={
                                    bgColor === "blue"
                                        ? "badge filter badge-blue active"
                                        : "badge filter badge-blue"
                                }
                                data-color="blue"
                                onClick={() => {
                                    handleColorClick("blue");
                                }}
                            />
                            <span
                                className={
                                    bgColor === "green"
                                        ? "badge filter badge-green active"
                                        : "badge filter badge-green"
                                }
                                data-color="green"
                                onClick={() => {
                                    handleColorClick("green");
                                }}
                            />
                            <span
                                className={
                                    bgColor === "orange"
                                        ? "badge filter badge-orange active"
                                        : "badge filter badge-orange"
                                }
                                data-color="orange"
                                onClick={() => {
                                    handleColorClick("orange");
                                }}
                            />
                            <span
                                className={
                                    bgColor === "red"
                                        ? "badge filter badge-red active"
                                        : "badge filter badge-red"
                                }
                                data-color="red"
                                onClick={() => {
                                    handleColorClick("red");
                                }}
                            />
                        </div>
                    </li>
                    {window.location.href.indexOf("/auth/") > -1 ? null : (
                        <li className="header-title">Sidebar Mini</li>
                    )}
                    {window.location.href.indexOf("/auth/") > -1 ? null : (
                        <li className="adjustments-line">
                            <div className="togglebutton switch-sidebar-mini">
                                <span className="label-switch">OFF</span>
                                <Switch
                                    onChange={onMiniClick}
                                    value={sidebarMini}
                                    onText=""
                                    offText=""
                                />
                                <span className="label-switch label-right">ON</span>
                            </div>
                        </li>
                    )}

                    <li className="button-container">
                        <a
                            href="https://www.creative-tim.com/product/now-ui-dashboard-pro-react?ref=nudr-fixed-plugin"
                            target="_blank"
                            className="btn btn-primary btn-block btn-round"
                        >
                            Buy now
                        </a>
                        <a
                            href="https://demos.creative-tim.com/now-ui-dashboard-pro-react/#/documentation/tutorial?ref=nudr-fixed-plugin"
                            className="btn btn-block btn-round btn-info"
                            target="_blank"
                        >
                            Documentation
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default FixedPlugin
