import React, { useState, useEffect } from 'react';
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import {useToast} from "../../../hooks";

const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
});

function ModalUserType ({ isOpen, setOpen, privileges, selected, setSelected, getTypePrivileges, history, insertData, updateData, setList }) {
    const [ checkedList, setCheckedList ] = useState([]);
    const { formState, handleSubmit, register, errors } = useForm({ mode: 'onBlur', validationSchema: schema });
    const toast = useToast();

    const toggle = () => {
        setSelected(null);
        setCheckedList([]);
        setOpen(false);
    };

    const getControlState = (name) => `has-label ${!formState.touched[name] ? '' : errors[name] ? 'has-danger' : 'has-success'}`;

    const isChecked = (id, list) => !!list.find(item => item === id);

    const addRemoveCheck = ({ target }) => {
        const value = Number(target.value);
        setCheckedList(prev => isChecked(value, prev) ? prev.filter(item => item !== value) : [...prev, value]);
    };

    const onSubmit = async ({ name, description }) => {
        const obj = {
            name,
            description,
            privileges: checkedList
        };
        const fetcher = selected ? updateData : insertData;

        const {response, error} = await fetcher(obj, selected?.id);
        if (response && response.statusText === 'OK') {
            const { data } = response;
            const validateList = item => item.id === data.data.id;
            setList(prev => !!prev.find(validateList) ? prev.map(item => {
                if(validateList(item)) {
                    item.name = data.data.name;
                    item.description = data.data.description;
                }
                return item;
            }) : [...prev, data.data]);
            toast.success(data.message);
            toggle();
        } else if (error)
            if (error.message.search('401') > 0) history.push('/auth/login');
    };

    useEffect(() => {

        const fetcher = async (id) => {
            const {response, error} = await getTypePrivileges(id);
            if (response && response.statusText === 'OK') {
                const { data } = response;
                setCheckedList(data.data.map(item => item.id));
            } else if (error)
                if (error.message.search('401') > 0) history.push('/auth/login');
        };

        if(selected) {
            fetcher(selected.id).then();
        }
    }, [selected]);

    return(
        <Modal isOpen={isOpen} toggle={toggle} size="xl" centered>
            <ModalHeader toggle={toggle}><strong>Crear Tipo de  Usuario</strong></ModalHeader>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    <Row>
                        <Col xs={6} md={6}>
                            <Row>

                                <Col xs={12} md={12}>
                                    <FormGroup className={getControlState('user')}>
                                        <label><strong>Nombre de Tipo de usuario</strong></label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            defaultValue={selected?.name || ''}
                                            ref={register}/>
                                    </FormGroup>
                                </Col>

                                <Col xs={12} md={12}>
                                    <FormGroup className={getControlState('description')}>
                                        <label><strong>Información</strong></label>
                                        <textarea
                                            name="description"
                                            className="form-control"
                                            rows="5"
                                            defaultValue={selected?.description || ''}
                                            ref={register}/>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={6} md={6}>
                            <Row>
                                <Col xs={12} md={12}>
                                    <strong><h6>Asignar Privilegios</h6></strong>
                                </Col>
                                <Col xs={12} md={12}>
                                    <Row>
                                        {privileges.map(item => (
                                            <Col xs={6} md={6} key={item.id}>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            type="checkbox"
                                                            value={item.id}
                                                            onChange={addRemoveCheck}
                                                            checked={isChecked(item.id, checkedList)}/>
                                                        <span className="form-check-sign" />
                                                        {item.name}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Col xs={12} md={12}>
                        <Button color="success" className="btn-round float-right" type="submit" disabled={!formState.isValid || checkedList.length === 0}>Guardar</Button>
                    </Col>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

export default ModalUserType;
