import {  createActions } from 'redux-actions';

const actions = createActions({
    SET_EVENT_LIST: (list) => (list),
    SET_SELECTED_EVENT: (event) => (event),
    PUSH_EVENT: (event) => (event),
    UPDATE_EVENT: (event) => (event),
});

const { setEventList, setSelectedEvent, pushEvent, updateEvent } = actions;

export default {
    setEventList,
    setSelectedEvent,
    pushEvent,
    updateEvent
};
