import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {CardBody, CardHeader, CardFooter, Button, Col} from "reactstrap";
import { FormContext, useForm } from 'react-hook-form';
import {PersonForm} from "../../components";
import {useApi, useToast} from "../../hooks";
import {personSchema} from "../../const";
import * as yup from "yup";

const schema = yup.object().shape({
    people: yup.array().of(personSchema),
});

function StaffPerson() {
    const [ event, setEvent ] = useState(0);
    const [ defaultValues, setDefaultValues ] = useState([]);
    const location = useLocation();
    const toast = useToast();

    const { addPersonEvent, getPersonDocumentNumber } = useApi();
    const methods = useForm({
        mode: 'onBlur',
        validationSchema: schema
    });

    const verifyDocumentNumber = async (event, index) => {
        const value = event.target.value;
        const {response, error} = await getPersonDocumentNumber(value);
        if (response && response.statusText === 'OK') {
            const { data } = response;
            setDefaultValues(prev => {
                const array = [...prev];
                array[index] = data.data;
                return array;
            });
        }
    };

    const onSubmit = async (values) => {
        console.log(values);
        /*const person = {
            ...values.people[0],
            documentType: values.people[0].documentType.value,
            gender:  values.people[0].gender.value,
            cityId: values.people[0].city.id,
            bloodType:  values.people[0].bloodType.value,
            academicTraining:  values.people[0].academicTraining.value
        };

        const personEvent = {
            eventId: event,
            oldWorker: Number(values.people[0].isWorked),
            // categoryId: category,
            state: 'ESPERA'
        };

        let formData = new FormData();
        formData.set('person', JSON.stringify(person));
        formData.set('person_event', JSON.stringify(personEvent));
        formData.append('photo', values.people[0].file);

        const {response, error} = await addPersonEvent(formData);
        if(response){
            const { data } = response;
            if(data.success) {
                toast.success("Solicitud enviada correctamente ");
                methods.reset();
                setDefaultValues([]);
            } else toast.error(data.message);
        } else if (error) {
            toast.error("Error al Enviar información");
        }*/
    };

    useEffect(() => {
        const paths = location.pathname.split('/');
        setEvent(Number(paths[4]));
        // setCategory(Number(paths[6]));
    }, [location]);

    return(
        <FormContext {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <CardHeader>
                    Inscribete como personal logistico
                </CardHeader>
                <CardBody>
                    <PersonForm
                        inputWorked
                        inputPhoto
                        fieldName="people"
                        index={0}
                        defaultValue={defaultValues[0]}
                        blurDocument={verifyDocumentNumber}
                    />
                </CardBody>
                <CardFooter>
                    <Col xs={12} md={12}>
                        <Button color="danger" className="btn-round" type="submit" disabled={!methods.formState.isValid}>Enviar Solicitud</Button>
                    </Col>
                </CardFooter>
            </form>
        </FormContext>
    );
}

export default StaffPerson
