import { useState, useEffect } from 'react';

function useScreenMeasurer() {
    const [ height, setHeight ] = useState(window.innerHeight);
    const [ width, setWidth ] = useState(window.innerWidth);

    useEffect(() => {

        const setWindowSize = () => {
            setHeight(window.innerHeight);
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', setWindowSize);

        return () => window.removeEventListener('resize', setWindowSize);
    }, []);

    return { scHeight: height, scWidth: width };
}

export default useScreenMeasurer;
