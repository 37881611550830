import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Form,
    Input,
    Col,
    Row
} from 'reactstrap';
import { useForm } from "react-hook-form";
import * as yup from 'yup';


const schema = yup.object().shape({
     user: yup.string().required(),
     information: yup.string().required(),
     type: yup.string().required(),
});

function ModalEditUsertype (props) {
    const {
        buttonLabel,
        className,
        modalOpen,
        setModalOpen,
        data,
        setData
    } = props;

    const TypeUsers = [
        { value: "1", label: 'SuperAdmin' },
        { value: "2",  label: 'Gerente' },
        { value: "3", label: 'Digitalizador' },
        { value: "4",  label: 'Coordinador' },
    
    ];
    

    const { formState, handleSubmit, register, errors } = useForm({
        mode: 'onBlur',
        validationSchema: schema
    });

    const getControlState = (name) => `has-label ${!formState.touched[name] ? '' : errors[name] ? 'has-danger' : 'has-success'}`

    const toggle = () => setModalOpen(!modalOpen);

    const onSubmit = (event) => {
        event.preventDefault();
    }

    const onChangeInput = (prop, value) =>{
        let user = data;
        user[prop] = value;
        console.log(user);
        setData(user);
    }

    return (
        <div>
            <Modal isOpen={modalOpen} toggle={toggle} className={className} size="lg" centered>
                <ModalHeader toggle={toggle}><strong>Actualizar Usuario</strong></ModalHeader>
                <Form onSubmit={onSubmit}>
                    <ModalBody>
                        <FormGroup className={getControlState('type')}>
                            <label>Tipo de Usuario</label>
                            <select name="type" className="form-control" ref={register}>
                                <option value="">Seleccione Tipo de Usario</option>
                                {
                                    TypeUsers.map(type => (
                                        <option key={type.value} className="form-control" value={type.value} >{type.label}</option>
                                    ))
                                }
                            </select>
                        </FormGroup>
                        <Row>
                            <Col xs={6} md={6}>
                                <FormGroup className={getControlState('user')}>
                                    <label>Nombre Usuario</label>
                                    <input
                                        type="text"
                                        name="user"
                                        className="form-control"
                                        value={data?.user}
                                        onChange={ (event) => onChangeInput('user', event.target.value) }
                                        ref={register}/>
                                </FormGroup>
                            </Col>
                            <Col xs={6} md={6}>
                                <FormGroup className={getControlState('information')}>
                                    <label>Informacion Adicional</label>
                                    <input
                                        type="text"
                                        name="information"
                                        className="form-control"
                                        value={data?.information}
                                        onChange={ (event) => onChangeInput('information', event.target.value) }
                                        ref={register}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col xs={12} md={12}>
                            <Button color="primary" className="btn-round float-right" type="submit">Actualizar</Button>
                        </Col>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
}

export default ModalEditUsertype;
