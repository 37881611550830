import React, { useEffect, useState } from "react";
import { useApi } from "../../hooks";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";
import { PanelHeader } from "../../components";
import ReactTable from 'react-table-v6';
import ModalDeleteUser from "./ModalDeleteProfile.view";
import ModalCreateUser from "./ModalCreateUser.view";
import ModalEditUser from "./ModalEditUser.view";

function User() {

    const { fetcher } = useApi();

    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [status, setStatus] = useState(null);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalCreate, setOpenModalCreate] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);

    useEffect(() => {
        getAllUsers()
    }, []);

    const getAllUsers = async () => {
        const {response, error} = await fetcher('GET',`user`,{});
        if (response){
            const {data, statusText} = response;
            if (statusText === "OK")
                setUsers(data.data);
        }else if (error)
            console.log(error)
    }

    const renderTable = (response_data) => {
        let data = response_data.map((prop, key) => {
            return {
                id: prop.id,
                user: prop.username.toUpperCase(),
                description: prop.type.description ? prop.type.description: 'Sin descripción',
                profile: prop.type.name,
                actions: (
                    // we've added some custom button actions
                    <div className="actions-right">
                        <Button
                            onClick={() => { 
                                let user = users.find(obj => obj.id === prop.id);
                                setStatus(user.status === 'ACTIVO' ? 'INACTIVO': 'ACTIVO');
                                setUser(user);
                                setOpenModalDelete(true);
                            }}
                            className="btn-icon btn-round"
                            color={prop.status === 'ACTIVO' ? "info" : "default" }
                            size="sm">
                            <i className={ prop.status === 'ACTIVO' ? "fa now-ui-icons ui-1_check" : "fa now-ui-icons ui-1_simple-delete"} />
                        </Button>{" "}
                        <Button
                            onClick={() => {
                                let user = users.find(obj => obj.id === prop.id);
                                setUser(user);
                                setOpenModalEdit(true);
                            }}
                            className="btn-icon btn-round"
                            color="warning"
                            size="sm">
                            <i className="fa fa-edit" />
                        </Button>{" "}
                        <Button
                            onClick={() => { 
                                let user = users.find(obj => obj.id === prop.id);
                                setStatus('OCULTO')
                                setUser(user);
                                setOpenModalDelete(true);
                            }}
                            className="btn-icon btn-round"
                            color="danger"
                            size="sm">
                            <i className="fa fa-times" />
                        </Button>{" "}
                    </div>
                )
            };
        })

        return data;
    }

    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xs={12} md={12}>
                                <Row>
                                    <Col xs={12} md={10}>
                                        <CardTitle tag="h4"><strong>Lista de Usuarios</strong></CardTitle>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <Button
                                            onClick={() => setOpenModalCreate(true)}
                                            color="success" className="btn-round float-right">Agregar Usuario</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs={12} md={12}>
                                <ReactTable
                                    data={renderTable(users)}
                                    filterable
                                    columns={[
                                        {
                                            Header: "Usuario",
                                            accessor: "user",
                                        },
                                        {
                                            Header: "Información",
                                            accessor: "description",
                                        },
                                        {
                                            Header: "Perfil",
                                            accessor: "profile",
                                        },
                                        {
                                            Header: "Acciones",
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false
                                        }
                                    ]}
                                    previousText='Anterior'
                                    nextText='Siguiente'
                                    pageText='Página'
                                    ofText='de'
                                    rowsText='Filas'
                                    showPaginationBottom
                                    minRows={1}
                                    defaultPageSize={18}
                                    pageSizeOptions={[10, 15, 18, 20, 25, 50]}
                                    className="-striped -highlight" />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <ModalCreateUser modalOpen={openModalCreate} setModalOpen={setOpenModalCreate} listUsers={users} setListUser={setUsers}/>
                <ModalEditUser modalOpen={openModalEdit} setModalOpen={setOpenModalEdit} currentUser={user} setCurrentUser={setUser} listUsers={users} setListUser={setUsers}/>
                <ModalDeleteUser modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} currentUser={user} listUsers={users} setListUser={setUsers} newStatus={status}/>
            </div>
        </>
    );
}

export default User;

//