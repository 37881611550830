import React, { useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";
import ReactTable from 'react-table-v6';
import { PanelHeader } from "../../components";
import { useEvents } from "../../hooks";
import ModalEvent from "./ModalEvents";

function Event() {

    const [selected, setSelected] = useState(null);
    const [ isOpenModal, setIsOpenModal ] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);

    const { eventList, addEvent, updateEventInList } = useEvents();

    const formatDate = date => {
        const dateSplit = date.split('-');
        return `${dateSplit[2][0]}${dateSplit[2][1]}/${dateSplit[1]}/${dateSplit[0]}`
    };

    const mapDataTable = (data) => {
        return data.map((prop, key) => ({
            id: prop.id,
            name: prop.name,
            date_in: formatDate(prop.dateStart),
            date_out: formatDate(prop.dateEnd),
            actions: (
                <div className="actions-right">
                    <Button
                        onClick={() => {
                            setSelected(prop);
                            setIsOpenModal(true);
                        }}
                        className="btn-icon btn-round"
                        color="warning"
                        size="sm">
                        <i className="fa fa-edit" />
                    </Button>
                </div>
            )
        }))
    };

    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xs={12} md={12}>
                                <Row>
                                    <Col xs={12} md={10}>
                                        <CardTitle tag="h4"><strong>Lista de Eventos</strong></CardTitle>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <Button
                                            onClick={() => setIsOpenModal(true)}
                                            color="success" className="btn-round float-right"
                                        >Agregar Evento</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs={12} md={12}>
                                <ReactTable
                                    data={mapDataTable(eventList)}
                                    filterable
                                    columns={[
                                        {
                                            Header: "Evento",
                                            accessor: "name"
                                        },
                                        {
                                            Header: "Fecha Inicio",
                                            accessor: "date_in"
                                        },
                                        {
                                            Header: "Fecha Finalización",
                                            accessor: "date_out"
                                        },
                                        {
                                            Header: "Acciones",
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false
                                        }
                                    ]}
                                    previousText='Anterior'
                                    nextText='Siguiente'
                                    pageText='Página'
                                    ofText='de'
                                    rowsText='Filas'
                                    showPaginationBottom
                                    minRows={1}
                                    defaultPageSize={18}
                                    pageSizeOptions={[10, 15, 18, 20, 25, 50]}
                                    className="-striped -highlight"
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
              <ModalEvent
                  isOpen={isOpenModal}
                  setIsOpen={setIsOpenModal}
                  selected={selected}
                  setSelected={setSelected}
                  addEvent={addEvent}
                  updateEvent={updateEventInList}
              />
            </div>
        </>
    );

}




export default Event;
