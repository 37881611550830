import {  createActions } from 'redux-actions';

const actions = createActions({
    SET_STATE_LIST: (list) => (list),
    SET_CITY_LIST: (list) => (list)
});

const { setStateList, setCityList } = actions;

export default {
    setStateList,
    setCityList
};
