import React, { useState, useEffect } from 'react';
import ReactTable from "react-table-v6";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button
} from "reactstrap";
import { PanelHeader } from "../../components";
import ModalLocation from "./ModalLocation";
import LocationView from "./LocationsView";
import {useLocations} from "../../hooks";
import LocationSelectedView from "./LocationSelectedView";

function Locality () {

    const [ selected, setSelected ] = useState(null);
    const [ isOpenModal, setIsOpenModal ] = useState(false);

    const { selectedEvent, locationList, locationsEvent, addLocation, updateLocationInList, addLocationEvent, removeLocationEvent } = useLocations();
    return(
        <>
            <PanelHeader size="sm"/>
            <div className="content">
                <Row>
                    <Col xs={12} md={6}>
                        <LocationView
                            data={locationList}
                            openModal={setIsOpenModal}
                            selectedEvent={selectedEvent}
                            selectedLocations={locationsEvent}
                            setSelected={setSelected}
                            addLocationEvent={addLocationEvent}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <LocationSelectedView data={locationList}  openModal={setIsOpenModal} selectedLocations={locationsEvent} setSelected={setSelected} removeLocationEvent={removeLocationEvent} />
                    </Col>
                </Row>
            </div>
            <ModalLocation
                isOpen={isOpenModal}
                setOpen={setIsOpenModal}
                selected={selected}
                setSelected={setSelected}
                addLocation={addLocation}
                updateLocation={updateLocationInList}
            />
        </>
    );
}

export default Locality;
