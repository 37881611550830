import React, { useRef, useState, useLayoutEffect } from 'react';
import { NavLink } from "react-router-dom";
import { Nav, Collapse, Button } from 'reactstrap';
import PerfectScrollbar from 'perfect-scrollbar';

import avatar from '../assets/img/user.jpg';
import logo from '../assets/img/logo-white.svg';
import ffvLogo from '../assets/img/logo-big.png';

var ps;
function Sidebar ({ minimizeSidebar, backgroundColor, routes, userPrivileges }) {
    const [ openAvatar, setOpenAvatar ] = useState(false);
    const [ collapses, setCollapses ] = useState(initCollapses(routes));
    const sidebar = useRef(null);

    const createLinks = ( routes ) => {
        return routes.map((prop, key) => {
            if(prop.collapse && validateCollapse(prop.views, userPrivileges)) {
                return(
                    <li
                        className={collapses[prop.state] ? "active" : ""}
                        key={key}
                    >
                        <a
                            href=""
                            data-toggle="collapse"
                            aria-expanded={collapses[prop.state]}
                            onClick={e => {
                                e.preventDefault();
                                setCollapses(prev => ({
                                    ...prev,
                                    [prop.state]: !prev[prop.state]
                                }));
                            }}
                        >
                            {prop.icon !== undefined ? (
                                <>
                                    <i className={prop.icon} />
                                    <p>
                                        {prop.name}
                                        <b className="caret" />
                                    </p>
                                </>
                            ) : (
                                <>
                                    <span className="sidebar-mini-icon">{prop.mini}</span>
                                    <span className="sidebar-normal">
                                        {prop.name}
                                        <b className="caret" />
                                    </span>
                                </>
                            )}
                        </a>
                        <Collapse isOpen={collapses[prop.state]}>
                            <ul className="nav">{createLinks(prop.views)}</ul>
                        </Collapse>
                    </li>
                );
            } else {
                if(prop.sidebar && validateOption(prop.privileges, userPrivileges)) {
                    return (
                        <li className={activeRoute(prop.layout + prop.path)} key={key}>
                            <NavLink to={prop.layout + prop.path} activeClassName="">
                                {prop.icon !== undefined ? (
                                    <>
                                        <i className={prop.icon} />
                                        <p>{prop.name}</p>
                                    </>
                                ) : (
                                    <>
                                        <span className="sidebar-mini-icon">{prop.mini}</span>
                                        <span className="sidebar-normal">{prop.name}</span>
                                    </>
                                )}
                            </NavLink>
                        </li>
                    );
                }
            }
        });
    };

    useLayoutEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(sidebar.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }

        return () => {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
            // to stop the warning of calling setState of unmounted component
            let id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        }
    }, []);

    return(
        <>
            <div className="sidebar" data-color={backgroundColor}>
                <div className="logo">
                    {/*<a
                        href="https://www.creative-tim.com?ref=nudr-sidebar"
                        className="simple-text logo-mini"
                        target="_blank"
                    >
                        <div className="logo-img">
                            <img src={logo} alt="react-logo" />
                        </div>
                    </a>
                    <a
                        href="https://www.creative-tim.com?ref=nudr-sidebar"
                        className="simple-text logo-normal"
                        target="_blank"
                    >
                        FFV
                    </a>*/}
                    <a
                        href="https://www.creative-tim.com?ref=nudr-sidebar"
                        className="simple-text logo-mini"
                        target="_blank"
                    >
                        <div className="logo-img">
                            <img src={logo} alt="react-logo" />
                        </div>
                    </a>
                    <a
                        href="https://www.creative-tim.com?ref=nudr-sidebar"
                        className="simple-text logo-normal"
                        target="_blank"
                    >
                        <div className="logo-img">
                            <img src={ffvLogo} alt="react-logo" />
                        </div>
                    </a>
                    <div className="navbar-minimize">
                        <Button
                            outline
                            className="btn-round btn-icon"
                            color="neutral"
                            id="minimizeSidebar"
                            onClick={() => minimizeSidebar()}
                        >
                            <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
                            <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
                        </Button>
                    </div>
                </div>

                <div className="sidebar-wrapper" ref={sidebar}>
                    <div className="user">
                        <div className="photo">
                            <img src={avatar} alt="Avatar" />
                        </div>
                        <div className="info">
                            <a
                                href="#pablo"
                                data-toggle="collapse"
                                aria-expanded={openAvatar}
                                onClick={() => setOpenAvatar(!openAvatar)}
                            >
                  <span>
                    Usuario
                    <b className="caret" />
                  </span>
                            </a>
                            <Collapse isOpen={openAvatar}>
                                <ul className="nav">
                                    <li>
                                        <a href="#pablo" onClick={e => e.preventDefault}>
                                            <span className="sidebar-mini-icon">MP</span>
                                            <span className="sidebar-normal">Mi Perfil</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#pablo" onClick={e => e.preventDefault}>
                                            <span className="sidebar-mini-icon">EP</span>
                                            <span className="sidebar-normal">Editar Perfil</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#pablo" onClick={e => e.preventDefault}>
                                            <span className="sidebar-mini-icon">OP</span>
                                            <span className="sidebar-normal">Opciones</span>
                                        </a>
                                    </li>
                                </ul>
                            </Collapse>
                        </div>
                    </div>
                    <Nav>{createLinks(routes)}</Nav>
                </div>
            </div>
        </>
    );
}

export default Sidebar;

const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
};

const initCollapses = (routes) => {
    let stateArray = {};
    routes.map((prop, key) => {
        if (prop.collapse) {
            stateArray = {
                ...stateArray,
                [prop.state]: !!prop.views.find((item) => activeRoute(item.layout + item.path) === 'active')
            };
        }
        return null;
    });
    return stateArray;
};

const validateCollapse = (views, privileges) => {
    /*for (let view of views) {
        return validateOption(view.privileges, privileges);
    }
    return false;*/
    return views.filter(view => validateOption(view.privileges, privileges)).length > 0;
};

const validateOption = (viewPrivileges, userPrivileges) => {
    return viewPrivileges[0] === 0 || viewPrivileges.filter(value => userPrivileges.includes(value)).length > 0;
};

