import React from 'react';
import { useSelector } from "react-redux";
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute ({ component: Component, ...rest }) {
    const user = useSelector(state => state.auth.user);
    return (
        <Route {...rest} render={props => {
            if (!!user) {
                // authorised so return component
                return <Component {...props} />
            }
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
        }} />
    );
}

export default PrivateRoute;
