import { handleActions } from 'redux-actions';
import { eventKeys } from '../keys';

const { SET_EVENT_LIST, SET_SELECTED_EVENT, PUSH_EVENT, UPDATE_EVENT } = eventKeys;

const eventsReducer = handleActions({
    [SET_EVENT_LIST]: (state, action) => ({
        ...state,
        list: action.payload
    }),
    [SET_SELECTED_EVENT]: (state, action) => ({
        ...state,
        selected: action.payload
    }),
    [PUSH_EVENT]: (state, action) => ({
        ...state,
        ...state.list.splice(0, 0, action.payload)
    }),
    [UPDATE_EVENT]: (state, action) => {
        const index = state.list.findIndex(event => event.id === action.payload.id);
        return {
            ...state,
            ...state.list.splice(index, 1, action.payload)
        }
    }
}, {
    selected: null,
    list: []
});

export default eventsReducer;
