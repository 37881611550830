import React from "react";
import { Container } from "reactstrap";

function Footer (props) {
    return (
        <footer
            className={"footer" + (props && props.default ? " footer-default" : "")}
        >
            <Container fluid={props.fluid}>
                <nav>
                    <ul>
                        <li>
                            <a
                                href="https://www.festivalvallenato.com"
                                className="mr-4-px"
                                target="_blank"
                            >
                                Festival de la leyenda Vallenata
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://presentation.creative-tim.com?ref=nudr-footer"
                                className="mr-4-px"
                                target="_blank"
                            >
                                Conocenos
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://blog.creative-tim.com?ref=nudr-footer"
                                target="_blank"
                            >
                                Blog
                            </a>
                        </li>
                    </ul>
                </nav>
                <div className="copyright">
                    &copy; {1900 + new Date().getYear()}, Designed & Coded by{" "}
                    <a href="https://www.makingid.com" target="_blank">
                        Making ID
                    </a>
                </div>
            </Container>
        </footer>
    );
}

export default Footer;
