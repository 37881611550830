import { handleActions } from 'redux-actions';
import { geographyKeys } from '../keys';

const { SET_CITY_LIST, SET_STATE_LIST } = geographyKeys;

const geographyReducer = handleActions({
    [SET_STATE_LIST]: (state, action) => ({
        ...state,
        states: action.payload
    }),
    [SET_CITY_LIST]: (state, action) => ({
        ...state,
        cities: action.payload
    })
}, {
    states: [],
    cities: []
});

export default geographyReducer;
