import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import {eventActions} from '../../store/actions';
import {useApi} from "./index";

function useEvents() {
    const dispatch = useDispatch();
    const selectedEvent = useSelector(store => store.events.selected);
    const eventList = useSelector(store => store.events.list);
    const history = useHistory();
    const { insertEvent, updateEvent } = useApi();

    const changeSelected = (id) => dispatch(eventActions.setSelectedEvent(id));

    const addEvent = async (event) => {
        const { response, error } = await insertEvent(event);
        if (response) {
            const { data } = response;
            dispatch(eventActions.pushEvent(data.data));
        } else if (error) {
            if (error.message.search('401') > 0) history.push('/auth/login');
        }
    };

    const updateEventInList = async (id, event) => {
        const { response, error } = await updateEvent(id, event);
        if (response) {
            const { data } = response;
            dispatch(eventActions.updateEvent(data.data));
        } else if (error) {
            if (error.message.search('401') > 0) history.push('/auth/login');
        }
    };

    return { selectedEvent, eventList, changeSelected, addEvent, updateEventInList }
}

export default useEvents;