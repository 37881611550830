import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";
import Select from "react-select";
import ReactTable from 'react-table-v6';
import { PanelHeader } from "../../components";
import ModalAddUser from './Modals/ModalAddUser';
import ModalEditUsertype from './Modals/ModalEditUsertype';
import {useApi} from '../../hooks';
import ModalUserType from "./Modals/ModalUserType";





function UserTypes() {

    const [userTypes, setUserTypes] = useState([]);
    const [privileges, setPrivileges] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [ selected, setSelected ] = useState(null);
    const { getUserTypes, getPrivileges, getUserTypePrivileges, insertUserType, updateUserType } = useApi();
    const history = useHistory();

    useEffect(() => {
        getAllUserTypes().then();
        getAllPrivileges().then();
    }, []);



    const getAllUserTypes = async () => {
        const {response, error} = await getUserTypes();
        if (response && response.statusText === 'OK'){
            const { data } = response;
            setUserTypes(data.data);
        } else if (error)
            if (error.message.search('401') > 0) history.push('/auth/login');
    };

    const getAllPrivileges = async () => {
        const {response, error} = await getPrivileges();
        if (response && response.statusText === 'OK') {
            const { data } = response;
            setPrivileges(data.data);
        } else if (error)
            if (error.message.search('401') > 0) history.push('/auth/login');
    };

    const mapData = (data) => (
        data.map(prop => ({
            ...prop,
            actions: (
                <div className="actions-right">
                    <Button
                        onClick={() => {
                            setSelected(prop);
                            setIsOpenModal(true);
                        }}
                        className="btn-icon btn-round"
                        color="warning"
                        size="sm">
                        <i className="fa fa-edit" />
                    </Button>
                </div>
            )
        }))
    );

    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Card>
                    <CardHeader>

                        <Row>
                            <Col xs={12} md={12}>
                                <Row>
                                    <Col xs={12} md={10}>
                                        <CardTitle tag="h4"> <strong>Lista de Tipos de  Usuarios</strong> </CardTitle>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <Button
                                            onClick={() => setIsOpenModal(true)}
                                            color="success" className="btn-round float-right">Nuevo Tipo de Usuario</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs={12} md={12}>
                                <ReactTable
                                    data={mapData(userTypes)}
                                    filterable
                                    columns={[
                                        {
                                            Header: "Nombre tipo de usuario",
                                            accessor: "name"
                                        },
                                        {
                                            Header: "información Adicional",
                                            accessor: "description"
                                        },
                                        {
                                            Header: "Acciones",
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false
                                        }
                                    ]}
                                    previousText='Anterior'
                                    nextText='Siguiente'
                                    pageText='Página'
                                    ofText='de'
                                    rowsText='Filas'
                                    showPaginationBottom
                                    minRows={1}
                                    defaultPageSize={18}
                                    pageSizeOptions={[10, 15, 18, 20, 25, 50]}
                                    className="-striped -highlight" />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
            <ModalUserType
                isOpen={isOpenModal}
                setOpen={setIsOpenModal}
                privileges={privileges}
                selected={selected}
                setSelected={setSelected}
                getTypePrivileges={getUserTypePrivileges}
                history={history}
                insertData={insertUserType}
                updateData={updateUserType}
                setList={setUserTypes}
            />
        </>
    );
}

export default UserTypes;
