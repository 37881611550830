import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Auth, Dashboard, Clean } from './layouts';
import { PrivateRoute } from './components';
import {store, persistor} from '../store'

import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.min.css';
// import "./assets/css/now-ui-dashboard.css";
import './assets/scss/now-ui-dashboard.scss?v=1.3.0';
import './assets/css/demo.css';

function App () {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <Switch>
                        <Route
                            path="/auth"
                            component={Auth}
                        />
                        <Route
                            path="/clean"
                            component={Clean}
                        />
                        <PrivateRoute
                            path="/admin"
                            component={Dashboard}
                        />
                        <Route exact path="/">
                            <Redirect to="/admin" />
                        </Route>
                    </Switch>
                </Router>
            </PersistGate>
        </Provider>
    );
}

export default App;
