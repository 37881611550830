import React, { useState, useEffect } from 'react';
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import {useGeography} from "../../hooks";
import Select from "react-select";

const schema = yup.object().shape({
    name: yup.string().required(),
    latitude: yup.number().required(),
    longitude: yup.number().required(),
});

function ModalLocation ({ isOpen, setOpen, selected, setSelected, addLocation, updateLocation }) {

    const [ stateSelected, setStateSelected ] = useState(null);
    const [ citySelected, setCitySelected ] = useState(null);

    const { formState, handleSubmit, register, errors } = useForm({
        mode: 'onBlur',
        validationSchema: schema
    });

    const { getStateCities, stateList, cityList } = useGeography();

    const onSubmit = async (values) => {
        const isUpdate = !!selected;

        const obj = {
            cityId: citySelected.id,
            name: values.name,
            lat: values.latitude,
            lng: values.longitude,
            status: 'ACTIVO'
        };

        if(isUpdate) updateLocation(selected.id, obj);
        else addLocation(obj);

        toggle();
    };

    const getControlState = (name) => `has-label ${!formState.touched[name] ? '' : errors[name] ? 'has-danger' : 'has-success'}`;

    const toggle = () => {
        setOpen(false);
        setSelected(null);
        setStateSelected(null);
        setCitySelected(null);
    };

    const handleStateSelect = (value) => {
        if(citySelected && stateSelected !== value) setCitySelected(null);
        setStateSelected(value)
    };

    useEffect(() => {
        if(selected) {
            const city = cityList.find(city => city.id === selected.cityId);
            const state = stateList.find(state => state.id === city.stateId);
            setCitySelected(city);
            setStateSelected(state);
        }
    }, [selected]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={toggle}><strong>{!selected ? 'Crear' : 'Modificar'} Localidad</strong></ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={12} md={12}>
                            <FormGroup className={getControlState('name')}>
                                <Label>Nombre*</Label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    ref={register}
                                    defaultValue={selected?.name || ''}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup className={`has-label ${stateSelected ? 'has-success' : ''}`}>
                                <Label>Departamento*</Label>
                                <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    placeholder="Seleccione un departamento"
                                    name="stateSelect"
                                    options={stateList}
                                    value={stateSelected}
                                    getOptionLabel={option => `${option.name}`}
                                    getOptionValue={option => `${option.id}`}
                                    noOptionsMessage={() => null}
                                    onChange={handleStateSelect}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup className={`has-label ${citySelected ? 'has-success' : ''}`}>
                                <Label>Ciudad*</Label>
                                <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    placeholder="Seleccione un municipio"
                                    name="citySelect"
                                    options={getStateCities(stateSelected?.id)}
                                    getOptionLabel={option => `${option.name}`}
                                    getOptionValue={option => `${option.id}`}
                                    noOptionsMessage={() => null}
                                    value={citySelected}
                                    onChange={(value) => setCitySelected(value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup className={getControlState('latitude')}>
                                <Label>Latitud*</Label>
                                <input
                                    type="text"
                                    name="latitude"
                                    className="form-control"
                                    ref={register}
                                    defaultValue={selected?.lat || ''}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup className={getControlState('longitude')}>
                                <Label>Longitud*</Label>
                                <input
                                    type="text"
                                    name="longitude"
                                    className="form-control"
                                    ref={register}
                                    defaultValue={selected?.lng || ''}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Col xs={12} md={12}>
                        <Button color="primary" className="btn-round float-right" type="submit" disabled={!formState.isValid || !stateSelected || !citySelected}>Guardar</Button>
                    </Col>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

export default ModalLocation;
