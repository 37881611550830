import React, { useState, useLayoutEffect, useRef } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import routes from '../routes';
import {AuthNavbar, Footer, FixedPlugin} from "../components";
import PerfectScrollbar from "perfect-scrollbar";

var ps;

function AuthLayout (props) {

    const [ filterColor, setFilterColor ] = useState('yellow');
    const fullPages = useRef(null);

    const handleColorClick = color => {
        setFilterColor(color);
    };

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (!prop.collapse) {
                if (prop.layout === '/auth') {
                    return(
                        <Route
                            path={prop.layout + prop.path}
                            component={prop.component}
                            key={key}
                        />
                    );
                }
            } else {
                return getRoutes(prop.views);
            }
        });
    };

    useLayoutEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(fullPages.current);
        }

        return () => {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
        }
    }, []);

    return(
        <>
            <AuthNavbar />
            <div className="wrapper wrapper-full-page" ref={fullPages}>
                <div
                    className="full-page section-image"
                    filter-color={filterColor}
                >
                    <Switch>
                        { getRoutes(routes) }
                        <Route exact path="/auth">
                            <Redirect to="/auth/login" />
                        </Route>
                    </Switch>
                    <Footer fluid />
                </div>
            </div>
            {/*<FixedPlugin
                bgColor={filterColor}
                handleColorClick={handleColorClick}
            />*/}
        </>
    );
}

export default AuthLayout;
