import React, { useState, useEffect } from 'react';
import {useApi, useToast} from "../../../hooks";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Form,
    Row,
    Col,
    Input,
    Label
} from 'reactstrap';
import Select from "react-select";
import { useForm } from "react-hook-form";
import * as yup from 'yup';

const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(), 
});

function ModalAddUser ({modalOpen, setModalOpen, listUsers, setListUser}) {

    const { fetcher } = useApi();
    const toast = useToast();

    const [usersTypes, setUsersTypes] = useState([]);
    const [privileges, setPrivileges] = useState([]);
    //const [arrayPrivileges, setArrayPrivileges] = useState([]);
    const [userType, setUserType] = useState(null);
    // const [user, setUser] = useState('');
 
   // const [description, setDescription] = useState('');

    const { formState, handleSubmit, register, errors } = useForm({ mode: 'onBlur', validationSchema: schema });

    useEffect(() => {
        
        getPrivileges();
    }, []);

    const isChecked = (privileges) => !!privileges.find(priv => priv.checked);

 
    const getPrivileges = async () =>{
        const {response, error} = await fetcher('GET',`privilege`,{});
        if (response){
            const {data, statusText} = response;
            if (statusText === "OK")
                setPrivileges(data.data.map(item => ({
                    ...item,
                    checked: false
                })));
        }else if (error)
            console.log(error)

    }

 

    const  postUserType = async (name, description) => {
        let data = {
            name,
            description,
            privileges: privileges.filter(item => item.checked).map(item => item.id)
        };
        console.log(data);

        
        const {response, error} = await fetcher('POST',`usertype`,data);

        if (response){
            const {data, statusText} = response;
            if(statusText == "OK"){
              console.log(data.data);
              setListUser(prev => {
                  prev.push(data.data);
                  return prev;
              })
              toast.success(data.message);
              setModalOpen(!modalOpen);
            }
        }

       
           
    }

    const onChangePrivileges = (event) => { addRemovePrivilege(event.target.value); }

    const addRemovePrivilege = (id) => {
        setPrivileges(prev => {
            const index = prev.findIndex(item => item.id === Number(id));
            return prev.map((item, key) => {
                if(key === index) item.checked = !item.checked;
                return item;
            } );
        });
    }

    const getControlState = (name) => `has-label ${!formState.touched[name] ? '' : errors[name] ? 'has-danger' : 'has-success'}`

    const toggle = () => setModalOpen(!modalOpen);


    const onSubmit = ({ name, description }) => {
        postUserType(name, description);
    }

    return (
        <div>
            <Modal isOpen={modalOpen} toggle={toggle} size="xl" centered>
                <ModalHeader toggle={toggle}><strong>Crear Tipo de  Usuario</strong></ModalHeader>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <Row>
                            <Col xs={6} md={6}>
                                <Row>
                                  
                                    <Col xs={12} md={12}>
                                        <FormGroup className={getControlState('user')}>
                                            <label><strong>Nombre de Tipo de usuario</strong></label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                ref={register}/>
                                        </FormGroup>
                                    </Col>
                                   
                                    <Col xs={12} md={12}>
                                        <FormGroup className={getControlState('description')}>
                                            <label><strong>Información</strong></label>
                                            <textarea
                                            name="description"
                                            className="form-control"
                                            rows="5"
                                            ref={register}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                
                            </Col>
                            <Col xs={6} md={6}>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <strong><h6>Asignar Privilegios</h6></strong>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <Row>
                                        {
                                            privileges.map((prop, key) => {
                                                return(
                                                    <Col xs={6} md={6} key={key}>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input 
                                                                type="checkbox" 
                                                                value={prop.id}
                                                                onChange={onChangePrivileges}
                                                                checked={prop.checked}/>
                                                                <span className="form-check-sign" />
                                                                {prop.name}
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                )
                                            })
                                        } 
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col xs={12} md={12}>
                            <Button color="success" className="btn-round float-right" type="submit" disabled={!formState.isValid || !isChecked(privileges)}>Guardar</Button>
                        </Col>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
}

export default ModalAddUser;
