import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

// reactstrap components
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container,
    Badge
} from "reactstrap";
import {useScreenMeasurer} from "../../hooks";

function AdminNavbar (props) {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ dropdownOpen, setDropdownOpen ] = useState(false);
    const [ color, setColor ] = useState('transparent');
    const history = useHistory();
    const { scWidth } = useScreenMeasurer();
    const sidebarToggle = useRef(null);

    const toggle = () => {
        if (isOpen) setColor('transparent');
        else setColor('white');

        setIsOpen(prevState => !prevState);
    };
    const dropdownToggle = e => {
        setDropdownOpen(prevState => !prevState);
    };

    const openSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        sidebarToggle.current.classList.toggle("toggled");
    };

    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    const updateColor = () => {
        if (window.innerWidth < 993 && isOpen) setColor('white');
        else setColor('transparent');
    };

    useEffect(() => {
        window.addEventListener("resize", updateColor);
        return () => window.removeEventListener("resize", updateColor);
    }, []);

    useEffect(() => {
        if( scWidth < 993 &&  document.documentElement.className.indexOf("nav-open") !== -1) {
            document.documentElement.classList.toggle("nav-open");
            sidebarToggle.current.classList.toggle("toggled");
        }
    }, [history, scWidth]);

    return(
        // add or remove classes depending if we are on full-screen-maps page or not
        <Navbar
            color={
                window.location.href.indexOf("full-screen-maps") !== -1
                    ? "white"
                    : color
            }
            expand="lg"
            className={
                window.location.href.indexOf("full-screen-maps") !== -1
                    ? "navbar-absolute "
                    : "navbar-absolute " +
                    (color === "transparent" ? "navbar-transparent " : "")
            }
        >
            <Container fluid>
                <div className="navbar-wrapper">
                    <div className="navbar-toggle">
                        <button
                            type="button"
                            ref={sidebarToggle}
                            className="navbar-toggler"
                            onClick={() => openSidebar()}
                        >
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                    </div>
                    <NavbarBrand href="/">{props.brandText}</NavbarBrand>
                </div>
                <NavbarToggler onClick={toggle}>
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                </NavbarToggler>
                <Collapse
                    isOpen={isOpen}
                    navbar
                    className="justify-content-end"
                >
                    <Nav navbar>
                        <Dropdown
                            nav
                            isOpen={dropdownOpen}
                            toggle={e => dropdownToggle(e)}
                        >
                            <DropdownToggle caret nav>
                                {props.selectedEvent
                                    ? props.eventList.length === 0
                                        ? ''
                                        : <Badge color="info">{props.eventList.find(item => item.id === props.selectedEvent).name}</Badge>
                                    : <Badge color="danger">Sin Evento</Badge>
                                }
                            </DropdownToggle>
                            <DropdownMenu right>
                                {props.eventList.length === 0 ?
                                    <DropdownItem tag="a">No hay eventos disponibles</DropdownItem> :
                                    props.eventList.map((item, key) => (
                                        <DropdownItem
                                            tag="a"
                                            key={key}
                                            onClick={() => props.toggleSelected(item.id)}
                                        >{item.name}</DropdownItem>
                                    ))
                                }
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default AdminNavbar;
