import React from 'react';
import {Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import * as yup from "yup";
import {useForm} from "react-hook-form";

const schema = yup.object().shape({
    name: yup.string().required(),
    date_in: yup.string().required(),
    date_out: yup.string().required(),
});

function ModalEvent ({ isOpen, setIsOpen, selected, setSelected, addEvent, updateEvent }) {

    const { formState, handleSubmit, register, errors } = useForm({
        mode: 'onBlur',
        validationSchema: schema
    });

    const getControlState = (name) => `has-label ${!formState.touched[name] ? '' : errors[name] ? 'has-danger' : 'has-success'}`;

    const toggle = () => {
        setIsOpen(false);
        setSelected(null);
    };

    const onSubmit = values => {
        const isUpdate = !!selected;
        const obj = {
            name: values.name,
            dateStart: values.date_in,
            dateEnd: values.date_out
        };

        if(isUpdate) updateEvent(selected.id, obj);
        else addEvent(obj);
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={toggle}><strong>{!selected ? 'Crear' : 'Modificar'} Evento</strong></ModalHeader>
                <ModalBody>
                    <FormGroup className={getControlState('name')}>
                        <label>Nombre del Evento</label>
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            defaultValue={selected?.name || ''}
                            ref={register}/>
                    </FormGroup>
                    <Row>
                        <Col xs={6} md={6}>
                            <FormGroup className={getControlState('date_in')}>
                                <label>Fecha de Inicio</label>
                                <input
                                    type="date"
                                    name="date_in"
                                    className="form-control"
                                    defaultValue={selected?.dateStart.split(' ')[0] || ''}
                                    ref={register}/>
                            </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                            <FormGroup className={getControlState('date_out')}>
                                <label>Fecha de Finalización</label>
                                <input
                                    type="date"
                                    name="date_out"
                                    className="form-control"
                                    defaultValue={selected?.dateEnd.split(' ')[0] || ''}
                                    ref={register}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Col xs={12} md={12}>
                        <Button color="success"
                                className="btn-round float-right"
                                type="submit" disabled={!formState.isValid}>Guardar</Button>
                    </Col>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

export default ModalEvent;