import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {useApi} from "./index";
import {geographyActions} from "../../store/actions";

function useGeography () {
    const dispatch = useDispatch();
    const stateList = useSelector(store => store.geography.states);
    const cityList = useSelector(store => store.geography.cities);
    const history = useHistory();
    const { getCities, getStates } = useApi();

    const getStateCities = (id) => cityList.filter(city => city.stateId === id);

    useEffect(() => {
        const fetchAllCities = async () => {
            const { response, error } = await getCities();
            if (response) {
                const { data } = response;
                dispatch(geographyActions.setCityList(data.data));
            } else if (error) {
                if (error.message.search('401') > 0) history.push('/auth/login');
            }
        };

        if(cityList.length === 0) fetchAllCities().then()
    }, [cityList]);

    useEffect(() => {
        const fetchAllStates = async () => {
            const { response, error } = await getStates();
            if (response) {
                const { data } = response;
                dispatch(geographyActions.setStateList(data.data));
            } else if (error) {
                if (error.message.search('401') > 0) history.push('/auth/login');
            }
        };

        if(stateList.length === 0) fetchAllStates().then()
    }, [stateList]);

    return { stateList, cityList, getStateCities }
}

export default useGeography;