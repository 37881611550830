import { handleActions } from 'redux-actions';
import { authKeys } from '../keys';

const { LOGIN_SUCCESS, LOGOUT } = authKeys;

const usersReducer = handleActions({
    [LOGIN_SUCCESS]: (state, action) => ({
        ...state,
        ...action.payload.user
    }),
    [LOGOUT]: (state, action) => ({})
}, {});

export default usersReducer;
