import React from 'react';

function PanelHeader({ size, children }) {
    return(
        <div
            className={
                "panel-header " +
                (size ? "panel-header-" + size : "")
            }
        >
            {children}
        </div>
    );
}

export default PanelHeader;
