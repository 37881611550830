import React from 'react';
import { PanelHeader } from "../../components";

function IndexView () {
    return(
        <>
            <PanelHeader size="sm"/>
            <div className="content">

            </div>
        </>
    );
}

export default IndexView;
